import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-personnalisation',
  templateUrl: './personnalisation.component.html',
  styleUrls: ['./personnalisation.component.scss']
})
export class PersonnalisationComponent implements OnInit {

  isLoading: boolean = false;

  general: boolean = false;
  algorithme: boolean = false;
  categorie: boolean = false;

  currentConfig: any;
  currentCategories: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.isLoading = true;
    let config = this.apiService.listConfig();
    let categories = this.apiService.listCatpoint();
    forkJoin([config, categories]).subscribe(results => {
      if (results[0].data.length) {
        this.currentConfig = results[0].data[0];
      }
      this.currentCategories = results[1].data
      this.currentCategories.sort((a, b) => a.id - b.id);
      this.general = true;
      this.isLoading = false;
    });
  }

  onGeneral() {
    this.general = true;
    this.algorithme = false;
    this.categorie = false;
  }

  onAlgorithme() {
    this.general = false;
    this.algorithme = true;
    this.categorie = false;
  }

  onCategorie() {
    this.general = false;
    this.algorithme = false;
    this.categorie = true;
  }

  updateConfig() {
    this.apiService.listConfig().subscribe(
      data => {
        this.currentConfig = data.data[0];
      },
      err => {
        console.log(err);
      }
    )
  }

  updateCategories() {
    this.isLoading = true;
    this.apiService.listCatpoint().subscribe(
      data => {
        this.currentCategories = data.data;
        this.currentCategories.sort((a, b) => a.id - b.id);
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    )
  }

}
