<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title" *ngIf="vue">
      <div class="back" routerLink="/statistiques">
        Voir les statistiques générales
      </div>
      <div class="vue">
        {{ vue.vue_title }}
      </div>
    </div>
    <div class="filters">
      <div class="title">
        Filtrer par dates
      </div>
      <div class="date-time">
        <div class="start">
          <ngx-datepicker [(ngModel)]="datePickerStart" [options]="optionsDatePicker" (ngModelChange)="setStartDate($event)"></ngx-datepicker>
        </div>
        <div class="end">
          <ngx-datepicker [(ngModel)]="datePickerEnd" [options]="optionsDatePicker" (ngModelChange)="setEndDate($event)"></ngx-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="contenu-page">
    <div class="stats">
      <div class="global" *ngIf="vue">
        <div class="bloc vues">
          <div class="title">
            Total vues
          </div>
          <div class="data">
            {{ vue.total_vues | number : '1.2-2' }}
          </div>
        </div>
      </div>
      <div class="graphs">
        <div class="graph vues" *ngIf="vuesChartData">
          <div class="title">
            Vues
          </div>
          <div class="data">
            <div class="chart-wrapper">
              <canvas baseChart 
                [datasets]="vuesChartData" 
                [labels]="vuesChartLabels" 
                [options]="lineChartOptions"
                [colors]="lineChartColors" 
                [legend]="lineChartLegend" 
                [chartType]="lineChartType" 
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
