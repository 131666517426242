<div class="subtitle">
  La modification algorithmique vous permet de définir l'ordre dans lequel vos contenus apparaîtrons.
</div>

<div class="explanations">
  <div class="title">
    Comment cela fonctionne ?
  </div>
  <div class="text">
    Vous avez la possibilité de donner des valeurs d'importance à 2 éléments : la position géographique et la date de publication.
  </div>
</div>

<div class="inputs">
  <div class="geo">
    <div class="title">
      Poids de la position géographique
    </div>
    <div class="range">
      <div class="start">
        0
      </div>
      <div class="input">
        <input name="geo" type="range" min="0" max="5" step="0.1" [(ngModel)]="geoValue" (input)="onChangeValues()" value="geoValue">
        <output class="value">{{ geoValue }}</output>
      </div>
      <div class="end">
        5
      </div>
    </div>
  </div>
  <div class="date">
    <div class="title">
      Poids de la date de publication
    </div>
    <div class="range">
      <div class="start">
        0
      </div>
      <div class="input">
        <input name="date" type="range" min="0" max="5" step="0.1" [(ngModel)]="dateValue" (input)="onChangeValues()" value="dateValue">
        <output class="value">{{ dateValue }}</output>
      </div>
      <div class="end">
        5
      </div>
    </div>
  </div>
</div>

<div class="examples">
  <div class="first">
    <div class="enonce">
      Exemple 1 : contenu à 4.5km de ma position publié il y a 2 jours
    </div>
    <div class="valeur">
      Valeur : {{ valueExampleOne }}
    </div>
    <div *ngIf="valueExampleOne<valueExampleTwo" class="print">
      S'affichera en 1er
    </div>
    <div *ngIf="valueExampleOne>valueExampleTwo" class="print">
      S'affichera en 2ème
    </div>
    <div *ngIf="valueExampleOne==valueExampleTwo" class="print">
      Les valeurs sont égales
    </div>
  </div>
  <div class="second">
    <div class="enonce">
      Exemple 2 : contenu à 1.5km de ma position publié il y a 7 jours
    </div>
    <div class="valeur">
      Valeur : {{ valueExampleTwo }}
    </div>
    <div *ngIf="valueExampleOne>valueExampleTwo" class="print">
      S'affichera en 1er
    </div>
    <div *ngIf="valueExampleOne<valueExampleTwo" class="print">
      S'affichera en 2ème
    </div>
    <div *ngIf="valueExampleOne==valueExampleTwo" class="print">
      Les valeurs sont égales
    </div>
  </div>
</div>

<div class="save">
  <div class="bouton" (click)="onSave()">
    Enregistrer
  </div>
</div>