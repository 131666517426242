import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/fr';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-single-stats',
  templateUrl: './single-stats.component.html',
  styleUrls: ['./single-stats.component.scss']
})
export class SingleStatsComponent implements OnInit {

  vuesChartData: ChartDataSets[];
  vuesChartLabels: Label[];

  lineChartOptions = {
    responsive: true,
    bezierCurve: false
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(90, 181, 224, 1)',
      backgroundColor: 'rgba(90, 181, 224, 0.28)',
    },
  ];

  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';

  isLoading: boolean = false;

  startDate: string;
  endDate: any;

  vue: any;

  optionsDatePicker: DatepickerOptions = {
    minYear: getYear(new Date()) - 5,
    maxYear: getYear(new Date()) + 10,
    placeholder: 'Sélectionner une date',
    format: 'dd-MM-yyyy',
    formatTitle: 'LLLL yyyy',
    formatDays: 'EEEEE',
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    locale: locale,
    inputClass: '',
    calendarClass: 'datepicker-default',
  };

  datePickerStart: any;
  datePickerEnd: any;

  sub: Subscription;

  appVue: string;

  constructor(private _Activatedroute: ActivatedRoute, private _router: Router, private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.sub = this._Activatedroute.paramMap.subscribe(params => { 
      this.appVue = params.get('appVue');
      this.initDates();
   });
  }

  initDates() {
    this.isLoading = true;

    let start: any = new Date();
    start.setMonth(start.getMonth() - 1);
    let end: any = new Date();

    let startYear = start.getFullYear();
    let startMonth = String(start.getMonth() + 1).padStart(2, '0');
    let startDay = String(start.getDate()).padStart(2, '0');
    let startHour = start.getHours();
    let startMinutes = start.getMinutes();
    this.startDate = startYear + '/' + startMonth + '/' + startDay + ' ' + startHour + ':' + startMinutes;

    let endYear = end.getFullYear();
    let endMonth = String(end.getMonth() + 1).padStart(2, '0');
    let endDay = String(end.getDate()).padStart(2, '0');
    let endHour = end.getHours();
    let endMinutes = end.getMinutes();
    this.endDate = endYear + '/' + endMonth + '/' + endDay + ' ' + endHour + ':' + endMinutes;

    this.datePickerStart = new Date(this.startDate);
    this.datePickerEnd = new Date(this.endDate);

    this.loadStats();
  }

  loadStats() {
    this.isLoading = true;
    this.apiService.getVueStats(this.appVue, this.startDate, this.endDate).subscribe(
      data => {
        this.vue = data.data;
        if (this.vue.total_vues == 0) {
          this.toastr.error('Aucune donnée à afficher');
        } else {
          this.initCharts();
        }
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    )
  }

  initCharts() {
    this.vuesChartData = [{
      data: [], 
      lineTension: 0 
    }];
    this.vuesChartLabels = [];

    this.vue.list.forEach(vue => {
      this.vuesChartData[0].data.push(vue.count);
      let label: any = new Date(vue.day);
      let y = label.getFullYear();
      let m = String(label.getMonth() + 1).padStart(2, '0');
      let d = String(label.getDate()).padStart(2, '0');
      label = d + '/' + m + '/' + y;
      this.vuesChartLabels.push(label);
    });
  }

  setStartDate(event) {
    let start: any = new Date(event);
    let startYear = start.getFullYear();
    let startMonth = String(start.getMonth() + 1).padStart(2, '0');
    let startDay = String(start.getDate()).padStart(2, '0');
    let startHour = start.getHours();
    let startMinutes = start.getMinutes();
    this.startDate = startYear + '/' + startMonth + '/' + startDay + ' ' + startHour + ':' + startMinutes;
    this.datePickerStart = new Date(this.startDate);
    this.loadStats();
  }

  setEndDate(event) {
    let end: any = new Date(event);
    let endYear = end.getFullYear();
    let endMonth = String(end.getMonth() + 1).padStart(2, '0');
    let endDay = String(end.getDate()).padStart(2, '0');
    let endHour = end.getHours();
    let endMinutes = end.getMinutes();
    this.endDate = endYear + '/' + endMonth + '/' + endDay + ' ' + endHour + ':' + endMinutes;
    this.datePickerEnd = new Date(this.endDate);
    this.loadStats();
  }

}
