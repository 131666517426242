import { Component, Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-personnalisation-categories',
  templateUrl: './personnalisation-categories.component.html',
  styleUrls: ['./personnalisation-categories.component.scss']
})
export class PersonnalisationCategoriesComponent implements OnInit {

  @Input() currentCategories: any;
  @Output() updateCategories = new EventEmitter<any>();

  @Directive({ selector: '[scrollTo]'})
  
  triggerNew: boolean = false;
  editModal: boolean = false;
  pointEdit: any = 0;

  langues: any = [];

  newCatDescription: string = '';
  newCatName: string = '';
  newCatColor: string = '';
  newCatMedia: File[] = [];

  editCatDescription: string = '';
  editCatName: string = '';
  editCatColor: string = '';
  editCatMedia: File[] = [];

  constructor(private elRef:ElementRef, private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.apiService.getLangs().subscribe(
      data => {
        this.langues = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  onTriggerNew() {
    this.triggerNew = !this.triggerNew;
    if (this.triggerNew) {
      setTimeout(() => {
        this.elRef.nativeElement.scrollIntoView({block: "end", behavior: "smooth"}); 
      }, 10);
    }
  }

  onSelectImgNew(event) {
    if (this.newCatMedia.length) {
      this.newCatMedia.length = 0;
    }
    this.newCatMedia.push(event.addedFiles[0]);
  }

  onRemoveImgNew(event) {
    this.newCatMedia.splice(this.newCatMedia.indexOf(event), 1);
  }

  onEdit(id) {
    this.editModal = true;
    this.pointEdit = this.currentCategories.filter(obj => {
      return obj.id == id
    });
    this.editCatDescription = this.pointEdit[0].description;
    this.editCatName = this.pointEdit[0].name;
    this.editCatColor = this.pointEdit[0].color;
    if (this.pointEdit[0].medias.length) {
      let element = this.pointEdit[0].medias[0];
      var blob = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
      xhr.responseType = "blob";
      xhr.onload = () => 
      {
          blob = xhr.response;
          let format = element.url.substring(element.url.lastIndexOf('.') + 1);
          let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
          var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
          this.editCatMedia.push(file);
      }
      xhr.send();
    }
  }

  onSelectImgEdit(event) {
    if (this.editCatMedia.length) {
      this.editCatMedia.length = 0;
    }
    this.editCatMedia.push(event.addedFiles[0]);
  }

  onRemoveImgEdit(event) {
    this.editCatMedia.splice(this.editCatMedia.indexOf(event), 1);
  }

  closeEditModal() {
    this.editModal = false;
    this.pointEdit = 0;
  }

  onDelete(id) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?\nCette action est irréversible.')){
      this.apiService.deleteCatpoint(id).subscribe(
        data => {
          if (!data.data.length) {
            this.updateCategories.next();
            this.toastr.success('Catégorie supprimée', '', {
              timeOut: 2000,
            });    
          } else {
            this.toastr.error('Des points ou variations sont liés à cette catégorie. Elle ne peut donc pas être supprimée.', '', {
              timeOut: 5000,
            });    
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  addCategorie() {
    this.apiService.addCatpoint(this.newCatName, {description: this.newCatDescription, color: this.newCatColor, medias: this.newCatMedia}).subscribe(
      data => {
        this.updateCategories.next();
        this.triggerNew = false;
        this.newCatDescription = '';
        this.newCatName = '';
        this.newCatColor = '';
        this.newCatMedia.length = 0;
        this.toastr.success('Catégorie ajoutée', '', {
          timeOut: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  editCategorie(id) {
    this.apiService.addCatpoint(this.editCatName, {catpoint_id: id, description: this.editCatDescription, color: this.editCatColor, medias: this.editCatMedia}).subscribe(
      data => {
        this.updateCategories.next();
        this.editModal = false;
        this.pointEdit = 0;
        this.toastr.success('Catégorie modifiée', '', {
          timeOut: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
  }

}