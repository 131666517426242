import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-point',
  templateUrl: './create-point.component.html',
  styleUrls: ['./create-point.component.scss']
})
export class CreatePointComponent implements OnInit {

  constructor(private apiService: ApiService, private _router: Router, private toastr: ToastrService, private zone: NgZone) { }

  isLoading: boolean = false;
  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  autocomplete: google.maps.places.Autocomplete;
  searchInAddPoint: string;

  newLat: string;
  newLon: string;
  newLatLonTitle: string;

  ngOnInit(): void {
    this.initAutocomplete();
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete") as HTMLInputElement
    );
    let autocomplete = this.autocomplete;
    autocomplete.addListener("place_changed", () => {
      this.isLoading = true;
      const place = autocomplete.getPlace();
      let city = '';
      place.address_components.forEach(element => {
        if (element.types.includes('locality')) {
          city = element.long_name || element.short_name;
        }
      });
      let title = place.name;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      let place_id = place.place_id;
      let description = place.formatted_address;
      this.searchInAddPoint = '';
      this.zone.run(() => {
        this.addPointFromGoogle(lat, lon, title, place_id, description, city);
      });
    });
  }

  newPointFromLatLon() {
    this.isLoading = true;
    this.apiService.addPoint(
      this.newLat, 
      this.newLon, 
      this.newLatLonTitle, 
      { 
        etat: '2',
      })
      .subscribe(      
      data => {
        this.navigateTo(['/edit-point/point/' + data.data.point_id])
      },
      err => {
        console.log(err);
      }
    );
  }

  addPointFromGoogle(lat, lon, title, place_id, description, city) {
    this.apiService.addPoint(
      lat, 
      lon, 
      title, 
      { googleplace_id: place_id, 
        description: description, 
        etat: '2',
        city: city
      })
      .subscribe(      
      data => {
        if (data.data.create) {
          this.toastr.success('Point ajouté');
          this.navigateTo(['/edit-point/point/' + data.data.point_id])
        } else {
          this.apiService.getPoint(data.data.point_id)      
          .subscribe(      
            data => {
              if (data.data.utilisateur_id == this.userID) {
                this.toastr.warning('Ce point existe déjà, vous pouvez cependant le modifier', '', {
                  timeOut: 3000,
                });
                this.navigateTo(['/edit-point/point/' + data.data.id])
              } else {
                this.toastr.error('Ce point a déjà été ajouté par un autre utilisateur, vous ne pouvez pas le rajouter ni le modifier', '', {
                  timeOut: 3000,
                });
                this.isLoading = false;
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  navigateTo(destination) {
    this._router.navigate(destination);
  }

}
