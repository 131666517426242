<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Mes circuits
    </div>
    <div class="search">
      <div class="searchbar">
        <input
          type="text"
          placeholder="Rechercher"
          (input)="onSearchChange($event.target.value)"
          />
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
        	<g>
        		<g>
        			<path d="M430.827,86.605c-49.161-51.53-124.712-59.44-180.6-28.393c-4.829,2.682-6.568,8.772-3.886,13.6
        				c2.682,4.83,8.772,6.567,13.6,3.886c51.118-28.401,115.436-18.236,156.371,24.671c42.236,44.811,46.992,111.607,14.317,161.128
        				c-45.008,68.362-142.217,78.664-200.5,20.37c-39.273-39.259-50.651-102.572-22.282-154.093c2.662-4.84,0.897-10.922-3.942-13.584
        				c-4.839-2.66-10.92-0.897-13.584,3.942c-0.613,1.115-1.703,3.193-2.754,5.324h0.001c-29.088,59.294-15.854,128.301,28.418,172.556
        				c67.148,67.162,179.31,55.53,231.345-23.507C484.648,215.946,480.07,138.85,430.827,86.605z"/>
        		</g>
        	</g>
        	<g>
        		<g>
        			<path d="M456.438,55.562c-74.091-74.081-194.651-74.084-268.745,0.001c-66.628,66.638-73.801,171.115-19.542,245.856
        				l-31.167,31.16c-10.927-5.23-24.745-3.542-34.155,5.869L14.667,426.62c-19.547,19.534-19.563,51.168-0.005,70.713
        				c19.539,19.552,51.172,19.56,70.715,0.002l88.175-88.164c9.406-9.408,11.1-23.226,5.868-34.154l31.163-31.17
        				c74.758,54.271,179.227,47.079,245.855-19.54C530.522,250.214,530.522,129.656,456.438,55.562z M159.407,395.027L71.23,483.193
        				c-11.719,11.728-30.689,11.739-42.424-0.005c-11.73-11.721-11.737-30.691,0.002-42.422l88.164-88.174
        				c3.897-3.898,10.242-3.9,14.139,0l28.295,28.295C163.306,384.785,163.306,391.129,159.407,395.027z M166.479,359.67
        				l-14.148-14.148l28.481-28.474c2.234,2.479,4.529,4.909,6.88,7.259c2.351,2.351,4.78,4.647,7.261,6.882L166.479,359.67z
        				 M442.293,310.163c-66.277,66.267-173.635,66.831-240.458,0c-66.743-66.736-66.346-174.099,0-240.456
        				c66.289-66.28,174.158-66.291,240.457,0C508.577,136.001,508.577,243.87,442.293,310.163z"/>
        		</g>
        	</g>
        	<g>
        		<g>
        			<path d="M230.37,84.108c-0.07-0.09-0.15-0.17-0.24-0.26c-3.911-3.901-10.242-3.901-14.152,0c-3.901,3.911-3.901,10.242,0,14.152
        				c0.09,0.09,0.17,0.17,0.26,0.25c3.931,3.651,10.072,3.561,13.892-0.25C233.94,94.179,234.03,88.038,230.37,84.108z"/>
        		</g>
        	</g>
        </svg>
      </div>
    </div>
    <div class="create">
      <a style="cursor:pointer;" (click)="createNewRoute()">
        Créer un circuit
      </a>
    </div>
  </div>
  <div class="filter">
    <div class="title">
      Filtrer par :
    </div>
    <div class="btn published" [class.active]="filteredByPublished" (click)="filterPublished()">
      Publié
    </div>
    <div class="btn draft" [class.active]="filteredByDraft" (click)="filterDraft()">
      Brouillon
    </div>
  </div>
  <div class="content-page" *ngIf="!isLoading">
    <div class="circuits">
      <ng-container *ngFor="let circuit of userRoutesFiltered | paginate: { itemsPerPage: 10, currentPage: page }; let index = index;">
        <div *ngIf="circuit.etat!==0" class="single-circuit {{index}}">
          <div class="image" (click)="onEdit(circuit.id)">
            <img *ngIf="circuit.medias[0]" src="{{ circuit.medias[0].url }}">
            <img *ngIf="!circuit.medias[0]" src="">
          </div>
          <div class="content">
            <div class="dates">
              <div class="created">
                Créé le {{ circuit.created_at }}
              </div>
              <div class="maj">
                Dernière mise à jour le {{ circuit.updated_at }}
              </div>
            </div>
            <div class="title">
              <div class="titre">
                {{ circuit.title }}
              </div>
              <div class="statut draft" *ngIf="circuit.etat==1">
                Brouillon
              </div>
              <div class="statut published" *ngIf="circuit.etat==2">
                Publié
              </div>
            </div>
            <div class="stats">
              <div class="points">
                {{ circuit.activePoints }} points
              </div>
            </div>
            <div class="actions">
              <div class="edit" (click)="onEdit(circuit.id)">
                <svg height="401pt" viewBox="0 -1 401.52289 401" width="401pt" xmlns="http://www.w3.org/2000/svg"><path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"/></svg>
                Éditer
              </div>
              <div *ngIf="circuit.etat==1" class="publish" (click)="onPublish(circuit.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 59.2 59.2" style="enable-background:new 0 0 59.2 59.2;" xml:space="preserve">
                	<g>
                		<path d="M51.062,21.561c-11.889-11.889-31.232-11.889-43.121,0L0,29.501l8.138,8.138c5.944,5.944,13.752,8.917,21.561,8.917
                			s15.616-2.972,21.561-8.917l7.941-7.941L51.062,21.561z M49.845,36.225c-11.109,11.108-29.184,11.108-40.293,0l-6.724-6.724
                			l6.527-6.527c11.109-11.108,29.184-11.108,40.293,0l6.724,6.724L49.845,36.225z"/>
                		<path d="M28.572,21.57c-3.86,0-7,3.14-7,7c0,0.552,0.448,1,1,1s1-0.448,1-1c0-2.757,2.243-5,5-5c0.552,0,1-0.448,1-1
                			S29.125,21.57,28.572,21.57z"/>
                		<path d="M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,40.57
                			c-6.065,0-11-4.935-11-11s4.935-11,11-11s11,4.935,11,11S35.638,40.57,29.572,40.57z"/>
                	</g>
                </svg>
                Publier
              </div>
              <div *ngIf="circuit.etat==2" class="draft" (click)="onDraft(circuit.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 59.049 59.049" style="enable-background:new 0 0 59.049 59.049;" xml:space="preserve">
                	<g>
                		<path d="M11.285,41.39c0.184,0.146,0.404,0.218,0.623,0.218c0.294,0,0.585-0.129,0.783-0.377c0.344-0.432,0.273-1.061-0.159-1.405
                			c-0.801-0.638-1.577-1.331-2.305-2.06l-7.398-7.398l7.629-7.629c7.334-7.333,18.003-9.836,27.839-6.534
                			c0.523,0.173,1.09-0.107,1.267-0.63c0.175-0.523-0.106-1.091-0.63-1.267c-10.562-3.545-22.016-0.857-29.89,7.016L0,30.368
                			l8.812,8.812C9.593,39.962,10.426,40.705,11.285,41.39z"/>
                		<path d="M50.237,21.325c-1.348-1.348-2.826-2.564-4.394-3.616c-0.458-0.307-1.081-0.185-1.388,0.273
                			c-0.308,0.458-0.185,1.08,0.273,1.388c1.46,0.979,2.838,2.113,4.094,3.369l7.398,7.398l-7.629,7.629
                			c-7.385,7.385-18.513,9.882-28.352,6.356c-0.52-0.187-1.093,0.084-1.279,0.604c-0.186,0.52,0.084,1.092,0.604,1.279
                			c3.182,1.14,6.49,1.693,9.776,1.693c7.621,0,15.124-2.977,20.665-8.518l9.043-9.043L50.237,21.325z"/>
                		<path d="M30.539,41.774c-2.153,0-4.251-0.598-6.07-1.73c-0.467-0.29-1.084-0.148-1.377,0.321c-0.292,0.469-0.148,1.085,0.321,1.377
                			c2.135,1.33,4.6,2.032,7.126,2.032c7.444,0,13.5-6.056,13.5-13.5c0-2.685-0.787-5.279-2.275-7.502
                			c-0.308-0.459-0.93-0.582-1.387-0.275c-0.459,0.308-0.582,0.929-0.275,1.387c1.267,1.893,1.937,4.102,1.937,6.39
                			C42.039,36.616,36.88,41.774,30.539,41.774z"/>
                		<path d="M30.539,18.774c2.065,0,4.089,0.553,5.855,1.6c0.474,0.281,1.088,0.125,1.37-0.351c0.281-0.475,0.125-1.088-0.351-1.37
                			c-2.074-1.229-4.451-1.879-6.875-1.879c-7.444,0-13.5,6.056-13.5,13.5c0,2.084,0.462,4.083,1.374,5.941
                			c0.174,0.354,0.529,0.56,0.899,0.56c0.147,0,0.298-0.033,0.439-0.102c0.496-0.244,0.701-0.843,0.458-1.338
                			c-0.776-1.582-1.17-3.284-1.17-5.06C19.039,23.933,24.198,18.774,30.539,18.774z"/>
                		<path d="M54.621,5.567c-0.391-0.391-1.023-0.391-1.414,0l-46.5,46.5c-0.391,0.391-0.391,1.023,0,1.414
                			c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l46.5-46.5C55.012,6.591,55.012,5.958,54.621,5.567z"/>
                	</g>
                </svg>
                Dépublier
              </div>
              <div class="delete" (click)="onDelete(circuit.id)">
                <svg height="427pt" viewBox="-40 0 427 427.00131" width="427pt" xmlns="http://www.w3.org/2000/svg"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/></svg>
                Supprimer
              </div>
              <div class="duplicate" (click)="onShowDuplicate(circuit.id, circuit.title)">
                <svg height="512pt" viewBox="-56 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m395.980469 112.582031-108.023438-108.023437c-2.960937-2.960938-6.949219-4.558594-11.019531-4.558594h-156.339844c-8.597656 0-15.578125 6.980469-15.578125 15.578125v89.445313h-89.441406c-8.597656 0-15.578125 6.976562-15.578125 15.578124v375.820313c0 8.597656 6.980469 15.578125 15.578125 15.578125h264.359375c8.601562 0 15.582031-6.980469 15.582031-15.578125v-89.445313h89.441407c8.597656 0 15.578124-6.976562 15.578124-15.578124v-267.796876c0-3.960937-1.523437-7.984374-4.558593-11.019531zm-26.597657 263.238281h-73.863281v-147.195312c0-4.035156-1.570312-8.03125-4.5625-11.023438l-108.019531-108.019531c-2.933594-2.9375-6.914062-4.5625-11.019531-4.5625h-35.738281v-73.863281h125.179687v92.445312c0 8.597657 6.980469 15.578126 15.578125 15.578126h92.445312zm-105.023437 105.023438h-233.203125v-344.664062h125.179688v92.445312c0 8.597656 6.980468 15.578125 15.582031 15.578125h92.441406zm-76.863281-322.636719 54.835937 54.835938h-54.835937zm159.855468-50.183593h-54.835937v-54.835938c6.855469 6.851562 49.367187 49.367188 54.835937 54.835938zm0 0"/></svg>
                Dupliquer
              </div>
              <div class="stats" (click)="onViewStats(circuit.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M440,0H40C17.944,0,0,17.944,0,40v248v32c0,22.056,17.944,40,40,40h134.416l-13.088,72h-31.432
                        c-9.152,0-17.376,5.08-21.472,13.264c-3.744,7.496-3.352,16.224,1.048,23.352c4.4,7.128,12.04,11.384,20.416,11.384h220.216
                        c8.376,0,16.008-4.256,20.416-11.384s4.8-15.856,1.048-23.352C367.488,437.08,359.264,432,350.112,432H318.68l-13.088-72H440
                        c22.056,0,40-17.944,40-40v-32V40C480,17.944,462.056,0,440,0z M312,448h38.112c3.048,0,5.792,1.696,7.16,4.424
                        c1.8,3.6,0.352,6.64-0.344,7.784c-0.72,1.136-2.792,3.792-6.816,3.792H129.888c-4.024,0-6.104-2.656-6.808-3.8
                        c-0.704-1.144-2.152-4.176-0.344-7.784c1.36-2.72,4.104-4.416,7.152-4.416H168h64c4.416,0,8-3.584,8-8c0-4.416-3.584-8-8-8
                        h-54.416l13.088-72h98.648l13.096,72H280c-4.416,0-8,3.584-8,8c0,4.416,3.584,8,8,8H312z M464,320c0,13.232-10.768,24-24,24H296
                        H184H40c-13.232,0-24-10.768-24-24v-24h448V320z M464,280H16V40c0-13.232,10.768-24,24-24h400c13.232,0,24,10.768,24,24V280z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M216,160h-80V80c0-4.416-3.584-8-8-8c-52.936,0-96,43.064-96,96c0,52.936,43.064,96,96,96c52.936,0,96-43.064,96-96
                        C224,163.584,220.416,160,216,160z M128,248c-44.112,0-80-35.888-80-80c0-41.408,31.632-75.576,72-79.6V168c0,4.416,3.584,8,8,8
                        h79.6C203.576,216.368,169.408,248,128,248z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M160,40c-4.416,0-8,3.584-8,8v88c0,4.416,3.584,8,8,8h88c4.416,0,8-3.584,8-8C256,83.064,212.936,40,160,40z M168,128
                        V56.4c37.736,3.76,67.84,33.864,71.6,71.6H168z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M440,32H280c-4.416,0-8,3.584-8,8v216c0,4.416,3.584,8,8,8h160c4.416,0,8-3.584,8-8V40C448,35.584,444.416,32,440,32z
                        M432,248H288V48h144V248z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M360,72h-48c-4.416,0-8,3.584-8,8s3.584,8,8,8h48c4.416,0,8-3.584,8-8S364.416,72,360,72z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M408,104h-96c-4.416,0-8,3.584-8,8s3.584,8,8,8h96c4.416,0,8-3.584,8-8S412.416,104,408,104z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M360,136h-48c-4.416,0-8,3.584-8,8s3.584,8,8,8h48c4.416,0,8-3.584,8-8S364.416,136,360,136z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M408,168h-96c-4.416,0-8,3.584-8,8s3.584,8,8,8h96c4.416,0,8-3.584,8-8S412.416,168,408,168z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M360,200h-48c-4.416,0-8,3.584-8,8s3.584,8,8,8h48c4.416,0,8-3.584,8-8S364.416,200,360,200z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M256,312h-32c-4.416,0-8,3.584-8,8c0,4.416,3.584,8,8,8h32c4.416,0,8-3.584,8-8C264,315.584,260.416,312,256,312z"/>
                    </g>
                  </g>
                </svg>
                Statistiques
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <pagination-controls 
        (pageChange)="onPageChange($event)"
        directionLinks="false"
      ></pagination-controls>
    </div>
  </div>
  <div class="duplication" *ngIf="showDuplicate">
    <app-modal-duplicate [id]="duplicatingId" [title]="duplicatingTitle"></app-modal-duplicate>
  </div>
</div>
