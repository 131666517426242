import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-step-two-publication',
  templateUrl: './step-two-publication.component.html',
  styleUrls: ['./step-two-publication.component.scss']
})
export class StepTwoPublicationComponent implements OnInit {

  isLoading: boolean = false;

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';
  publicationId = JSON.parse(localStorage.getItem('currentPublicationId'));
  publication: any = [];
  allPoints: any = [];

  points: any = [];
  medias: any = [];
  mediasToDelete: any = [];

  constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;

    //Let time for the backend to update the medias ID
    setTimeout(() => {

      this.apiService.getPublication(this.publicationId).subscribe(
        data => {
    
          this.publication = data.data;
          this.allPoints = [...data.data.points, ...data.data.variations];
          this.allPoints.sort(function(a, b){
            return a.pivot.order - b.pivot.order;
          });
  
          if (this.publication.audio == 0 && this.publication.video == 0) {
            if (this.publication.medias.length) {
              //Publi photos
              this.allPoints.forEach((point, index) => {
                if (point.medias.length) {
                  point.medias.forEach(media => {
                    this.publication.medias.forEach(element => {
                      if (element.name == media.name && element.size == media.size) {
                        if (!media.from_this_publication) media.from_this_publication = 1;
                        (element.count) ? element.count += 1 : element.count = 1;
                      }
                    });
                  });
                  
                  let mediasToPushInPoint: any = [];
                  point.medias.forEach(element => {
                    if (element.from_this_publication) {
                      mediasToPushInPoint.push(element);
                    }
                  });
                  this.points.push(mediasToPushInPoint);
                } else {
                  this.points.push([]);
                }
      
                this.medias.push(this.publication.medias);
      
              });
            }
          } else if (this.publication.audio == 1) {
            // Publi audio
            this.allPoints.forEach((point, index) => {
              if (point.medias.length) {
                point.medias.forEach(media => {
                  if (media.url.slice(media.url.length - 3) == 'mp3') {
                    this.publication.medias.forEach(element => {
                      if (element.name == media.name && element.size == media.size) {
                        if (!media.from_this_publication) media.from_this_publication = 1;
                        (element.count) ? element.count += 1 : element.count = 1;
                      }
                    });
                  }
                });
    
                let mediasToPushInPoint: any = [];
                point.medias.forEach(element => {
                  if (element.from_this_publication) {
                    mediasToPushInPoint.push(element);
                  }
                });
                this.points.push(mediasToPushInPoint);
              } else {
                this.points.push([]);
              }
  
              let mediasMP3 = this.publication.medias.filter(function( obj ) {
                return obj.url.slice(obj.url.length - 3) == 'mp3'
              });
    
              this.medias.push(mediasMP3);
    
            });
          } else if (this.publication.video == 1) {
            // Publi vidéo
            this.publication.video_codes.forEach(video => {
              // youtube = 1, vimeo = 2, fb = 3, embed = 4
              if (video.type == 1) {
                video.frame = "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/" + video.code + "' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
              } else if (video.type == 2) {
                video.frame = "<iframe src='https://player.vimeo.com/video/" + video.code + "' width='100%' height='100%' frameborder='0' allow='autoplay ; fullscreen' allowfullscreen></iframe>"
              } else if (video.type == 3) {
                video.frame = "<iframe src='https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F" + video.code + "%2F&width=500&show_text=false&height=280&appId' width='500' height='280' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>"
              } else if (video.type == 4) {
                video.frame = video.code;
              }
            });

            this.allPoints.forEach((point, index) => {
              if (point.video_codes.length) {
                point.video_codes.forEach(video => {
                  this.publication.video_codes.forEach(element => {
                    if (element.code == video.code && element.type == video.type) {
                      if (!video.from_this_publication) video.from_this_publication = 1;
                      (element.count) ? element.count += 1 : element.count = 1;
                    }
                  });
                });
    
                let mediasToPushInPoint: any = [];
                point.video_codes.forEach(element => {
                  // youtube = 1, vimeo = 2, fb = 3, embed = 4
                  if (element.type == 1) {
                    element.frame = "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/" + element.code + "' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
                  } else if (element.type == 2) {
                    element.frame = "<iframe src='https://player.vimeo.com/video/" + element.code + "' width='100%' height='100%' frameborder='0' allow='autoplay ; fullscreen' allowfullscreen></iframe>"
                  } else if (element.type == 3) {
                    element.frame = "<iframe src='https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F" + element.code + "%2F&width=500&show_text=false&height=280&appId' width='500' height='280' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>"
                  } else if (element.type == 4) {
                    element.frame = element.code;
                  }
                  if (element.from_this_publication) {
                    mediasToPushInPoint.push(element);
                  }
                });
                this.points.push(mediasToPushInPoint);
              } else {
                this.points.push([]);
              }

              let mediasVideos = this.publication.video_codes;
              this.medias.push(mediasVideos);

            });

          }
          this.isLoading = false;
        },
        err => {
          console.log(err);
        }
      );  

    }, 1000);
  }

  drop(event: CdkDragDrop<string[]>) {
    let pointsIndex = event.previousContainer.id.replace('cdk-drop-list-','');
    let mediasIndex = event.previousIndex;
    let hasMedia = false;
    
    // Publi audio ou images
    if ((this.publication.audio && !this.publication.video) || (!this.publication.audio && !this.publication.video)) {
      if (this.points[pointsIndex].some(e => e.name == this.medias[pointsIndex][mediasIndex].name && e.size == this.medias[pointsIndex][mediasIndex].size)) {
        hasMedia = true;
      }
    }
    // Publi video
    if (!this.publication.audio && this.publication.video) {
      if (this.points[pointsIndex].some(e => e.code == this.medias[pointsIndex][mediasIndex].code && e.type == this.medias[pointsIndex][mediasIndex].type)) {
        hasMedia = true;
      }
    }
    
    if (!hasMedia) {
      this.points[pointsIndex].push(this.medias[pointsIndex][mediasIndex]);
      if (this.medias[pointsIndex][mediasIndex].count) {
        this.medias[pointsIndex][mediasIndex].count += 1;    
      } else {
        this.medias[pointsIndex][mediasIndex].count = 1;
      }
    } else {
      this.toastr.warning('Média déjà ajouté à ce point', '', {
        timeOut: 2000,
      });
    }
  }

  onRemoveMedia(item, index) {
    this.points[index].splice(this.points[index].indexOf(item), 1);
    // Publi audio ou images
    if ((this.publication.audio && !this.publication.video) || (!this.publication.audio && !this.publication.video)) {
      this.publication.medias.forEach(element => {
        if (element.name == item.name && element.size == item.size) {
          element.count -= 1;
          this.mediasToDelete.push(item.id);
        }
      });
    }
    // Publi video
    if (!this.publication.audio && this.publication.video) {
      this.publication.video_codes.forEach(element => {
        if (element.code == item.code && element.type == item.type) {
          element.count -= 1;
          this.mediasToDelete.push({point_id: this.allPoints[index].id, id: item.id});
        }
      });
    }
  }

  goBack() {
    this.router.navigate(['/create-publication-1']);
  }

  onFinished(etat) {
    this.isLoading = true;

    // Publi audio ou images
    if ((this.publication.audio && !this.publication.video) || (!this.publication.audio && !this.publication.video)) {
      let mediasPoints = [];
      this.points.forEach((element, index) => {
        if (element.length) {
          element.forEach(el => {
            if (this.allPoints[index].point_id) {
              mediasPoints.push([el.id, this.allPoints[index].point_id, this.allPoints[index].id]);
            } else {
              mediasPoints.push([el.id, this.allPoints[index].id, 0]);
            }
          });
        }
      });

      let linkApi = this.apiService.linkMediasPoints(mediasPoints);
      let saveApi = this.apiService.addPublication(this.publication.title, {article_id: this.publication.id, description: this.publication.description, etat: etat, url: this.publication.url, audio: this.publication.audio, video: this.publication.video});
  
      if (this.mediasToDelete.length) {
        this.mediasToDelete.forEach((id, index) => {
          this.apiService.unlinkMedia(id).subscribe(
            data => {
              if (index + 1 == this.mediasToDelete.length) {
                forkJoin([linkApi, saveApi]).subscribe(results => {
                  this.router.navigate(['/publications']);
                  this.isLoading = false;
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        });
      } else {
        forkJoin([linkApi, saveApi]).subscribe(results => {
          this.router.navigate(['/publications']);
          this.isLoading = false;
        });
      }
    }

    // Publi video
    if (!this.publication.audio && this.publication.video) {
      if (this.mediasToDelete.length) {
        this.mediasToDelete.forEach((toDelete, index) => {
          this.apiService.pointUnlinkVideos(toDelete.point_id, toDelete.id).subscribe(
            data => {
              if (index + 1 == this.mediasToDelete.length) {
                this.points.forEach((element, index) => {
                  let mediasToLink: any = [];
                  if (element.length) {
                    element.forEach((el, i) => {
                      mediasToLink.push({type: el.type, code: el.code, order: i + 1});
                    });
                    this.apiService.addPoint(this.allPoints[index].lat, this.allPoints[index].lon, this.allPoints[index].title, {article_id: this.publication.id, point_id: this.allPoints[index].id, catpoint_id: this.allPoints[index].catpoin_id, googleplace_id: this.allPoints[index].googleplace_id, description: this.allPoints[index].description, moreinfo: this.allPoints[index].moreinfo, etat: this.allPoints[index].etat, order: this.allPoints[index].pivot.order, statut_edit: this.allPoints[index].statut_edit, video_codes: mediasToLink}).subscribe(
                      data => {
                        if (index + 1 == this.points.length) {                
                          this.apiService.addPublication(this.publication.title, {article_id: this.publication.id, description: this.publication.description, etat: etat, url: this.publication.url, audio: this.publication.audio, video: this.publication.video}).subscribe(
                            data => {
                              this.router.navigate(['/publications']);
                              this.isLoading = false;
                            },
                            err => {
                              console.log(err);
                            }
                          )
                        }
                      },
                      err => {
                        console.log(err);
                      }
                    )
                  }
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        });
      } else {
        this.points.forEach((element, index) => {
          let mediasToLink: any = [];
          if (element.length) {
            element.forEach((el, i) => {
              mediasToLink.push({type: el.type, code: el.code, order: i + 1});
            });
            this.apiService.addPoint(this.allPoints[index].lat, this.allPoints[index].lon, this.allPoints[index].title, {article_id: this.publication.id, point_id: this.allPoints[index].id, catpoint_id: this.allPoints[index].catpoin_id, googleplace_id: this.allPoints[index].googleplace_id, description: this.allPoints[index].description, moreinfo: this.allPoints[index].moreinfo, etat: this.allPoints[index].etat, order: this.allPoints[index].pivot.order, statut_edit: this.allPoints[index].statut_edit, video_codes: mediasToLink}).subscribe(
              data => {
                if (index + 1 == this.points.length) {
                  this.apiService.addPublication(this.publication.title, {article_id: this.publication.id, description: this.publication.description, etat: etat, url: this.publication.url, audio: this.publication.audio, video: this.publication.video}).subscribe(
                    data => {
                      this.router.navigate(['/publications']);
                      this.isLoading = false;
                    },
                    err => {
                      console.log(err);
                    }
                  )
                }
              },
              err => {
                console.log(err);
              }
            )
          }
        });
      }
    }

  }

}