import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {

  userPublications;
  userPublicationsFiltered;
  isLoading: boolean = false;
  searchValue: string;
  filteredByPublished: boolean = false;
  filteredByDraft: boolean = false;
  page: number = 1;
  collection: any[];  

  constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getPublications().subscribe(
      data => {
        this.userPublications = data.data.reverse();
        this.userPublications.forEach(element => {
          this.getTimes(element);
          this.createFormattedTitle(element);
          element.app_link = 'https://geoquebec.ca/publication/' + element.id + '/' + element.title_formatted;
          element.fb_share = 'https://www.facebook.com/sharer/sharer.php?u=' + element.app_link;
          var encodedStr = element.description;
          var parser = new DOMParser;
          var dom = parser.parseFromString(encodedStr, 'text/html');
          var decodedString = dom.body.textContent;
          element.description = decodedString;
        });
        this.userPublicationsFiltered = this.userPublications;
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  createNewPublication() {
    localStorage.removeItem('currentPublicationId');
    this.router.navigate(['/create-publication-1']);
  }

  getTimes(publication) {
    let date;
    if (publication.published_at) {
      var t = publication.published_at.split(/[- :]/);
      var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
      date = new Date(d);
    } else {
      var dateParts = publication.created_at.split("/");
      date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
    }

    let published = this.getDateString(date);
    publication.published_formatted = published;

    let now = new Date();
    if (date.getTime() > now.getTime()) {
      publication.programmed = true;
    }
  }

  getDateString(dateObject) {
    let date = dateObject;

    let dd = date.getDate();
    let mm = date.getMonth()+1;
    let yyyy = date.getFullYear();

    let day: string;
    let month: string;
  
    (dd<10)? day = '0' + String(dd) : day = String(dd);
    (mm<10)? month = '0' + String(mm) : month = String(mm);
  
    let today = String(day) + '/' + String(month) + '/' + String(yyyy);
    return today;
  }

  onEdit(id, event?) {
    if (event) {
      if (event.target.tagName.toLowerCase() === 'a') {
        return;
      }
    }
    localStorage.setItem('currentPublicationId', id);
    this.router.navigate(['/create-publication-1']);
  }

  onPublish(id) {
    this.isLoading = true;
    let publication = this.userPublications.find(el => el.id === id);
    this.apiService.addPublication(publication.title, {article_id: publication.id, description: publication.description, etat: '2', url: publication.url, audio: publication.audio, video: publication.video}).subscribe(
      data => {
        this.reloadPublications('Publication publiée');
      },
      err => {
        console.log(err);
      }
    );
  }

  onDraft(id) {
    this.isLoading = true;
    let publication = this.userPublications.find(el => el.id === id);
    this.apiService.addPublication(publication.title, {article_id: publication.id, description: publication.description, etat: '1', url: publication.url, audio: publication.audio, video: publication.video}).subscribe(
      data => {
        this.reloadPublications('Publication dépubliée');
      },
      err => {
        console.log(err);
      }
    );
  }

  onDelete(id) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer cette publication ?\nCette action est irréversible.')){
      this.isLoading = true;
      let publication = this.userPublications.find(el => el.id === id);
      this.apiService.addPublication(publication.title, {article_id: publication.id, etat: '0'}).subscribe(
        data => {
          this.reloadPublications('Publication supprimée');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onViewStats(id) {
    let title = this.userPublications.find(x => x.id == id).title_formatted;
    this.router.navigate(['/statistiques', '/publication/' + id + '/' + title]);
  }

  reloadPublications(toaster) {
    this.apiService.getPublications().subscribe(
      data => {
        this.userPublications = data.data.reverse();
        this.userPublications.forEach(element => {
          var encodedStr = element.description;
          var parser = new DOMParser;
          var dom = parser.parseFromString(encodedStr, 'text/html');
          var decodedString = dom.body.textContent;
          element.description = decodedString;
        });
        this.userPublicationsFiltered = this.userPublications;
        this.isLoading = false;
        this.toastr.success(toaster);
      },
      err => {
        console.log(err);
      }
    );
  }

  onSearchChange(searchValue: string) {
    this.page = 1;
    this.searchValue = searchValue;
    if (this.filteredByPublished || this.filteredByDraft) {
      let filter = '';
      if (this.filteredByDraft) filter = '1';
      if (this.filteredByPublished) filter = '2';
      this.userPublicationsFiltered = this.userPublications.filter(el => el.etat == filter);
      if (searchValue) {
        this.userPublicationsFiltered = this.userPublicationsFiltered.filter(el => el.title.toLowerCase().includes(searchValue.toLowerCase()));
      } else {
        this.userPublicationsFiltered = this.userPublicationsFiltered;
      }
    } else {
      if (searchValue) {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.title.toLowerCase().includes(searchValue.toLowerCase()));
      } else {
        this.userPublicationsFiltered = this.userPublications;
      }
    }
  }

  createFormattedTitle(publication) {
    publication.title_formatted = publication.title.replace(/\s/g, '_').normalize('NFD').replace(/[^\w]/g, '').replace(/_/g, '-');
  }

  filterPublished() {
    this.filteredByDraft = false;
    if (this.filteredByPublished) {
      if (this.searchValue) {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
      } else {
        this.userPublicationsFiltered = this.userPublications;
      }
      this.filteredByPublished = false;
    } else {
      if (this.searchValue) {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
        this.userPublicationsFiltered = this.userPublicationsFiltered.filter(el => el.etat == '2');
      } else {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.etat == '2');
      }
      this.filteredByPublished = true;
    }
  }

  filterDraft() {
    this.filteredByPublished = false;
    if (this.filteredByDraft) {
      if (this.searchValue) {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
      } else {
        this.userPublicationsFiltered = this.userPublications;
      }
      this.filteredByDraft = false;
    } else {
      if (this.searchValue) {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
        this.userPublicationsFiltered = this.userPublicationsFiltered.filter(el => el.etat == '1');
      } else {
        this.userPublicationsFiltered = this.userPublications.filter(el => el.etat == '1');
      }
      this.filteredByDraft = true;
    }
  }

  onPageChange(event) {
    this.page = event;
    const top = document.querySelector(".filter");
    if (top) top.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
