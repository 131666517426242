import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  error: string = null;
  formLogin: boolean = true;
  formRegister: boolean = false;
  isLoading: boolean = false;

  private userSub: Subscription;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe(user => {
      if (user) {
        this.router.navigate(['/publications']);
      }
    });
  }

  toggleLogin() {
    this.formLogin = true;
    this.formRegister = false;
  }

  toggleRegister() {
    // this.formLogin = false;
    // this.formRegister = true;
  }

  onLogin(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;

    const email = form.value.login;
    const password = form.value.password;

    this.authService.login(email, password).subscribe(
      resData => {
        this.router.navigate(['/publications']);
      },
      errRes => {
        this.error = errRes.message;
        this.isLoading = false;
      }
    );

    form.reset();
  }

  onRegister(form: NgForm) {
    // if (!form.valid) {
    //   return;
    // }

    // this.isLoading = true;

    // const firstname = form.value.firstname;
    // const name = form.value.name;
    // const email = form.value.email;
    // const password = form.value.password;

    // this.authService.signup(firstname, name, email, password).subscribe(
    //   resData => {
    //     this.router.navigate(['/publications']);
    //   },
    //   errRes => {
    //     this.error = errRes.message;
    //     this.isLoading = false;
    //   }
    // );

    // form.reset();
  }

}
