import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, throwError, BehaviorSubject } from 'rxjs';
import { share, map, tap, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';

import { User } from './user.model';

interface AuthResponseData {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);

  private loginExpirationTimer: any;

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private http: HttpClient, private router: Router) { }

  signup(
    firstname: string,
    name: string,
    email: string,
    password: string
  ) {
    const form: FormData = new FormData();
    form.set('firstname', firstname);
    form.set('name', name);
    form.set('email', email);
    form.set('password', password);

    return this.http.post<AuthResponseData>(
      `${environment.baseApiUrl}user/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        const expirationDate = new Date(new Date().getTime() + 6 * 3600 * 1000);
        const user = new User(resData.data.user_id, expirationDate);
        this.user.next(user);
        this.autoLogout(6 * 3600 * 1000);
        localStorage.setItem('userData', JSON.stringify(user));
      })
    );

  }

  login(
    email: string,
    password: string
  ) {
    const form: FormData = new FormData();
    form.set('email', email);
    form.set('password', password);

    return this.http.post<AuthResponseData>(
      `${environment.baseApiUrl}user/connect`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        if (resData.data.answer == 3) {
          throw new Error('Mot de passe erroné');
        } else if (resData.data.answer == 2) {
          throw new Error('Utilisateur inconnu');
        } else if (resData.data.answer == 1) {
          const expirationDate = new Date(new Date().getTime() + 6 * 3600 * 1000);
          const user = new User(resData.data.user_id, expirationDate);
          this.user.next(user);
          this.autoLogout(6 * 3600 * 1000);
          localStorage.setItem('userData', JSON.stringify(user));
        }
      })
    );
  }

  autoLogin() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    const loadedUser = new User(userData.id, new Date(userData.expiration));

    if (loadedUser.id) {
      this.user.next(loadedUser);
      const expirationDuration = new Date(userData.expiration).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }

  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.removeItem('userData');
    if (this.loginExpirationTimer) {
      clearTimeout(this.loginExpirationTimer);
    }
    this.loginExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    // this.loginExpirationTimer = setTimeout(() => {
    //   this.logout();
    // }, expirationDuration);
  }

}
