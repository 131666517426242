import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  isLoading: boolean = false;
  newUserTriggered: boolean = false;
  users: any;
  
  constructor(private apiService: ApiService, private authService: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.isLoading = true;
    this.apiService.listUsers().subscribe(
      data => {
        this.users = data.data;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  onAddUser() {
    this.newUserTriggered = true;
  }

  closeNewUser() {
    this.newUserTriggered = false;
  }

  onRegister(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;

    this.newUserTriggered = false;

    const firstname = form.value.firstname;
    const name = form.value.name;
    const email = form.value.email;
    const password = form.value.password;

    this.authService.signup(firstname, name, email, password).subscribe(
      resData => {
        this.toastr.success('Utilisateur ajouté', '', {
          timeOut: 2000,
        });
        this.getUsers();
      },
      errRes => {
        this.toastr.error('Une erreur est survenue');
        this.isLoading = false;
      }
    );

    form.reset();
  }

  onDelete(user) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?\nCette action est irréversible.')){
      this.isLoading = true;
      this.apiService.deleteUser(user.id).subscribe(
        data => {
          this.getUsers();
          this.toastr.success('Utilisateur supprimé');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

}
