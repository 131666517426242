<div class="container">
  <div class="categories">
    <div class="categorie" *ngFor="let cat of currentCategories">
      <div class="image" *ngIf="cat.medias.length">
        <img src="{{cat.medias[0].url}}" alt="">
      </div>
      <div class="content">
        <div class="name">
          {{ cat.name }}
        </div>
        <div class="description">
          {{ cat.description }}
        </div>
        <div class="color">
          <!-- <div class="title">Couleur</div> -->
          <div class="couleur" [style.background]="cat.color"></div>
        </div>
      </div>
      <div class="actions">
        <div class="edit" (click)="onEdit(cat.id)">
          <svg height="401pt" viewBox="0 -1 401.52289 401" width="401pt" xmlns="http://www.w3.org/2000/svg"><path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"/></svg>
        </div>
        <div class="delete" (click)="onDelete(cat.id)">
          <svg height="427pt" viewBox="-40 0 427 427.00131" width="427pt" xmlns="http://www.w3.org/2000/svg"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/></svg>
        </div>
      </div>
    </div>
  </div>

  <div class="add-categorie">
    <div class="bouton" (click)="onTriggerNew()" [ngClass]="{'active':triggerNew}">
      Ajouter une catégorie
    </div>
  </div>

  <div *ngIf="triggerNew" class="new-categorie" scrollTo>
    <div class="field name">
      <div class="input">
        <input
          type="text"
          placeholder="Nom"
          [(ngModel)]="newCatName"
        />
      </div>
    </div>
    <div class="field description">
      <div class="input">
        <input
          type="text"
          placeholder="Description"
          [(ngModel)]="newCatDescription"
        />
      </div>
    </div>
    <div class="picker">
      <input
        [value]="newCatColor"
        [style.background]="newCatColor"
        [(colorPicker)]="newCatColor"
        placeholder="Ajouter une couleur"
      />
    </div>
    <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectImgNew($event)">
      <ngx-dropzone-label>
        <div class="label">Glissez ou cliquez pour déposer une image</div>
      </ngx-dropzone-label>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of newCatMedia" [file]="f" [removable]="true" [multiple]="false" (removed)="onRemoveImgNew(f)">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </div>
    <div class="add-cat">
      <div class="bouton" (click)="addCategorie()" [ngClass]="{'disabled' : !newCatName || !newCatColor || !newCatMedia.length}">
        Ajouter la catégorie
      </div>
    </div>
  </div>
</div>

<div class="modal-edit" *ngIf="editModal">
  <div class="content">
    <div class="head">
      <div class="close" (click)="closeEditModal()">
        <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
        </svg>
      </div>
    </div>
    <div class="title">
      Vous modifiez la catégorie {{ editCatName }}
    </div>
    <div class="fields">
      <div class="field name">
        <div class="input">
          <input
            type="text"
            placeholder="Nom"
            [(ngModel)]="editCatName"
          />
        </div>
      </div>
      <div class="field description">
        <div class="input">
          <input
            type="text"
            placeholder="Description"
            [(ngModel)]="editCatDescription"
          />
        </div>
      </div>
      <div class="picker">
        <input
          [value]="editCatColor"
          [style.background]="editCatColor"
          [(colorPicker)]="editCatColor"
          placeholder="Ajouter une couleur"
        />
      </div>
      <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectImgEdit($event)">
        <ngx-dropzone-label>
          <div class="label">Glissez ou cliquez pour déposer une image</div>
        </ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of editCatMedia" [file]="f" [removable]="true" [multiple]="false" (removed)="onRemoveImgEdit(f)">
          <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </div>
      <div class="edit-cat">
        <div class="bouton" (click)="editCategorie(pointEdit[0].id)" [ngClass]="{'disabled' : !editCatName || !editCatColor || !editCatMedia.length}">
          Modifier la catégorie
        </div>
      </div>
    </div>
  </div>
</div>