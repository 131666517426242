import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  userPoints;
  userPointsFiltered;
  isLoading: boolean = false;

  constructor(private toastr: ToastrService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.isLoading = true;

    let getPoints = this.apiService.getPointsLight({user_id: this.userID});
    let getVariations = this.apiService.getVariations({user_id: this.userID});

    forkJoin([getPoints, getVariations]).subscribe(results => {
      this.userPoints = [...results[0].data, ...results[1].data];
      this.userPointsFiltered = this.userPoints;
      this.isLoading = false;
    });
  }

  onSearchNameChange(searchValue: string) {
    if (searchValue) {
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      this.userPointsFiltered = this.userPoints;
    }
  }

  onSearchCityChange(searchValue: string) {
    if (searchValue) {
      this.userPointsFiltered = this.userPoints.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      this.userPointsFiltered = this.userPoints;
    }
  }

  onDelete(point) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer ce point ?\nCette action est irréversible.')){
      if (point.point_id) {
        this.apiService.deletePoint(this.userID, point.point_id, point.id).subscribe(
          data => {
            this.userPoints = this.userPoints.filter(function( obj ) {
              return obj.id !== point.id && obj.point_id !== point.point_id;
            });
            this.userPointsFiltered = this.userPointsFiltered.filter(function( obj ) {
              return obj.id !== point.id && obj.point_id !== point.point_id;
            });
            this.toastr.success('Point supprimé');
          },
          err => {
            console.log(err);
            this.toastr.error('Ce point est utilisé dans un circuit ou une publication. Il ne peut pas être supprimé', '', {
              timeOut: 3000,
            });
          }
        );
      } else {
        this.apiService.deletePoint(this.userID, point.id).subscribe(
          data => {
            this.userPoints = this.userPoints.filter(function( obj ) {
              return obj.id !== point.id;
            });
            this.userPointsFiltered = this.userPointsFiltered.filter(function( obj ) {
              return obj.id !== point.id;
            });
            this.toastr.success('Point supprimé');
          },
          err => {
            console.log(err);
            this.toastr.error('Ce point est utilisé dans un circuit ou une publication. Il ne peut pas être supprimé', '', {
              timeOut: 3000,
            });
          }
        );
      }
    }
  }

  overRoute(point) {
    point.hoverRoute = true;
  }

  outRoute(point) {
    point.hoverRoute = false;
  }

  overPublication(point) {
    point.hoverPublication = true;
  }

  outPublication(point) {
    point.hoverPublication = false;
  }

}
