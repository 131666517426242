import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-personnalisation-algorithme',
  templateUrl: './personnalisation-algorithme.component.html',
  styleUrls: ['./personnalisation-algorithme.component.scss']
})
export class PersonnalisationAlgorithmeComponent implements OnInit {

  @Input() currentConfig: any;
  @Output() updateConfig = new EventEmitter<any>();

  configID: any;

  appTitle: any;
  geoValue: number = 0;
  dateValue: number = 0;

  valueExampleOne: number = 0;
  valueExampleTwo: number = 0;

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.currentConfig) {
      this.configID = this.currentConfig.id;
      if (this.currentConfig.geo_value) this.geoValue = this.currentConfig.geo_value;
      if (this.currentConfig.date_value) this.dateValue = this.currentConfig.date_value;
      if (this.geoValue && this.dateValue) {
        this.valueExampleOne = Math.round(((4.5 * this.geoValue) + (2 * this.dateValue) + Number.EPSILON) * 100) / 100;
        this.valueExampleTwo = Math.round(((1.5 * this.geoValue) + (7 * this.dateValue) + Number.EPSILON) * 100) / 100;
      }
    } else {
      this.appTitle = 'Configuration de base';
    }
  }

  onChangeValues() {
    this.valueExampleOne = Math.round(((4.5 * this.geoValue) + (2 * this.dateValue) + Number.EPSILON) * 100) / 100;
    this.valueExampleTwo = Math.round(((1.5 * this.geoValue) + (7 * this.dateValue) + Number.EPSILON) * 100) / 100;
  }

  onSave() {
    this.apiService.addConfig(this.appTitle, {config_id: this.configID, geo_value: String(this.geoValue), date_value: String(this.dateValue)}).subscribe(
      data => {
        this.updateConfig.next(data.data.config_id);
        this.toastr.success('Configuration sauvegardée', '', {
          timeOut: 2000,
        });
      },
      err => {
        console.log(err);
      }
    )
  }

}
