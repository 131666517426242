import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-personnalisation-generale',
  templateUrl: './personnalisation-generale.component.html',
  styleUrls: ['./personnalisation-generale.component.scss']
})
export class PersonnalisationGeneraleComponent implements OnInit {

  @Input() currentConfig: any;
  @Output() updateConfig = new EventEmitter<any>();

  configID: any;

  appTitle: string;
  metaDescription: string;
  colors: any = [
    {
      title: 'Couleur principale',
      id: 'colorp',
      value: 'undefined'
    },
    {
      title: 'Couleur alternative',
      id: 'colora',
      value: 'undefined'
    },
    {
      title: 'Couleur texte principal',
      id: 'color_txtp',
      value: 'undefined'
    },
    {
      title: 'Couleur texte alternatif',
      id: 'color_txta',
      value: 'undefined'
    },
    {
      title: 'Couleur des liens',
      id: 'color_link',
      value: 'undefined'
    },
    {
      title: 'Couleur du navigateur',
      id: 'color_nav',
      value: 'undefined'
    }
  ];

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.currentConfig) {
      this.configID = this.currentConfig.id;
      this.appTitle = this.currentConfig.apptitle;
      this.metaDescription = this.currentConfig.description;
      this.colors[0].value = this.currentConfig.colorp;
      this.colors[1].value = this.currentConfig.colora;
      this.colors[2].value = this.currentConfig.color_txtp;
      this.colors[3].value = this.currentConfig.color_txta;
      this.colors[4].value = this.currentConfig.color_link;
      this.colors[5].value = this.currentConfig.color_nav; 
    }
  }

  onSave() {
    let colorp = this.colors[0].value;
    let colora = this.colors[1].value;
    let color_txtp = this.colors[2].value;
    let color_txta = this.colors[3].value;
    let color_link = this.colors[4].value;
    let color_nav = this.colors[5].value;
    this.apiService.addConfig(this.appTitle, {config_id: this.configID, description: this.metaDescription, colorp: colorp, colora: colora, color_txtp: color_txtp, color_txta: color_txta, color_link: color_link, color_nav: color_nav}).subscribe(
      data => {
        this.updateConfig.next(data.data.config_id);
        this.toastr.success('Configuration sauvegardée', '', {
          timeOut: 2000,
        });
      },
      err => {
        console.log(err);
      }
    )
  }

}
