<div class="create-circuit">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-create">
    <div class="title">
    {{ pageTitle }}
    </div>
    <div class="subtitle">
      Étape 2 / 4
    </div>
  </div>
  <div class="breadcrumb">
    <li>
      <a style="cursor:pointer;" routerLink="/create-circuit-1" routerLinkActive="active" class="done">
        Information sur le circuit >
      </a>
    </li>
    <li>
      <a style="cursor:pointer;" routerLink="/create-circuit-2" routerLinkActive="active">
        Ajoutez vos points >
      </a>
    </li>
    <li>
      <a style="cursor:pointer;" routerLink="/create-circuit-3" routerLinkActive="active" [ngClass]="{disabled: !showPoints}">
        Configurez vos points >
      </a>
    </li>
    <li>
      <a style="cursor:pointer;" routerLink="/create-circuit-4" routerLinkActive="active" [ngClass]="{disabled: !showPoints || !checkAllPointsConfigured}">
        Validation
      </a>
    </li>
  </div>
  <div class="content">
    <div class="left">
      <div class="explication">
        Ajoutez l'ensemble de vos points pour définir le tracé de votre circuit.
        <br>
        Vous pourrez modifier les informations (titre, description, ...) de vos points sur l'écran suivant.
      </div>
      <div class="contenu-left">
        <div class="add-new-point">
          <input
            type="text"
            placeholder="Rechercher un point Google Maps"
            [(ngModel)]="searchInAddPoint"
            id="autocomplete"
            />
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
            <g>
              <g>
                <path d="M430.827,86.605c-49.161-51.53-124.712-59.44-180.6-28.393c-4.829,2.682-6.568,8.772-3.886,13.6
                  c2.682,4.83,8.772,6.567,13.6,3.886c51.118-28.401,115.436-18.236,156.371,24.671c42.236,44.811,46.992,111.607,14.317,161.128
                  c-45.008,68.362-142.217,78.664-200.5,20.37c-39.273-39.259-50.651-102.572-22.282-154.093c2.662-4.84,0.897-10.922-3.942-13.584
                  c-4.839-2.66-10.92-0.897-13.584,3.942c-0.613,1.115-1.703,3.193-2.754,5.324h0.001c-29.088,59.294-15.854,128.301,28.418,172.556
                  c67.148,67.162,179.31,55.53,231.345-23.507C484.648,215.946,480.07,138.85,430.827,86.605z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M456.438,55.562c-74.091-74.081-194.651-74.084-268.745,0.001c-66.628,66.638-73.801,171.115-19.542,245.856
                  l-31.167,31.16c-10.927-5.23-24.745-3.542-34.155,5.869L14.667,426.62c-19.547,19.534-19.563,51.168-0.005,70.713
                  c19.539,19.552,51.172,19.56,70.715,0.002l88.175-88.164c9.406-9.408,11.1-23.226,5.868-34.154l31.163-31.17
                  c74.758,54.271,179.227,47.079,245.855-19.54C530.522,250.214,530.522,129.656,456.438,55.562z M159.407,395.027L71.23,483.193
                  c-11.719,11.728-30.689,11.739-42.424-0.005c-11.73-11.721-11.737-30.691,0.002-42.422l88.164-88.174
                  c3.897-3.898,10.242-3.9,14.139,0l28.295,28.295C163.306,384.785,163.306,391.129,159.407,395.027z M166.479,359.67
                  l-14.148-14.148l28.481-28.474c2.234,2.479,4.529,4.909,6.88,7.259c2.351,2.351,4.78,4.647,7.261,6.882L166.479,359.67z
                   M442.293,310.163c-66.277,66.267-173.635,66.831-240.458,0c-66.743-66.736-66.346-174.099,0-240.456
                  c66.289-66.28,174.158-66.291,240.457,0C508.577,136.001,508.577,243.87,442.293,310.163z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M230.37,84.108c-0.07-0.09-0.15-0.17-0.24-0.26c-3.911-3.901-10.242-3.901-14.152,0c-3.901,3.911-3.901,10.242,0,14.152
                  c0.09,0.09,0.17,0.17,0.26,0.25c3.931,3.651,10.072,3.561,13.892-0.25C233.94,94.179,234.03,88.038,230.37,84.108z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="search-in-saved">
          <input
            type="text"
            placeholder="Rechercher dans vos points enregistrés"
            (keyup)="onSearchInSaved(searchInSaved, $event)"
            (keydown)="keepFocus($event)"
            [(ngModel)]="searchInSaved"
            (focus)="onFocus()"
            (blur)="onBlur()"
            />
          <svg (click)="onSearchInSaved(searchInSaved)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
            <g>
              <g>
                <path d="M430.827,86.605c-49.161-51.53-124.712-59.44-180.6-28.393c-4.829,2.682-6.568,8.772-3.886,13.6
                  c2.682,4.83,8.772,6.567,13.6,3.886c51.118-28.401,115.436-18.236,156.371,24.671c42.236,44.811,46.992,111.607,14.317,161.128
                  c-45.008,68.362-142.217,78.664-200.5,20.37c-39.273-39.259-50.651-102.572-22.282-154.093c2.662-4.84,0.897-10.922-3.942-13.584
                  c-4.839-2.66-10.92-0.897-13.584,3.942c-0.613,1.115-1.703,3.193-2.754,5.324h0.001c-29.088,59.294-15.854,128.301,28.418,172.556
                  c67.148,67.162,179.31,55.53,231.345-23.507C484.648,215.946,480.07,138.85,430.827,86.605z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M456.438,55.562c-74.091-74.081-194.651-74.084-268.745,0.001c-66.628,66.638-73.801,171.115-19.542,245.856
                  l-31.167,31.16c-10.927-5.23-24.745-3.542-34.155,5.869L14.667,426.62c-19.547,19.534-19.563,51.168-0.005,70.713
                  c19.539,19.552,51.172,19.56,70.715,0.002l88.175-88.164c9.406-9.408,11.1-23.226,5.868-34.154l31.163-31.17
                  c74.758,54.271,179.227,47.079,245.855-19.54C530.522,250.214,530.522,129.656,456.438,55.562z M159.407,395.027L71.23,483.193
                  c-11.719,11.728-30.689,11.739-42.424-0.005c-11.73-11.721-11.737-30.691,0.002-42.422l88.164-88.174
                  c3.897-3.898,10.242-3.9,14.139,0l28.295,28.295C163.306,384.785,163.306,391.129,159.407,395.027z M166.479,359.67
                  l-14.148-14.148l28.481-28.474c2.234,2.479,4.529,4.909,6.88,7.259c2.351,2.351,4.78,4.647,7.261,6.882L166.479,359.67z
                   M442.293,310.163c-66.277,66.267-173.635,66.831-240.458,0c-66.743-66.736-66.346-174.099,0-240.456
                  c66.289-66.28,174.158-66.291,240.457,0C508.577,136.001,508.577,243.87,442.293,310.163z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M230.37,84.108c-0.07-0.09-0.15-0.17-0.24-0.26c-3.911-3.901-10.242-3.901-14.152,0c-3.901,3.911-3.901,10.242,0,14.152
                  c0.09,0.09,0.17,0.17,0.26,0.25c3.931,3.651,10.072,3.561,13.892-0.25C233.94,94.179,234.03,88.038,230.37,84.108z"/>
              </g>
            </g>
          </svg>
          <div class="dropdown-in-saved" *ngIf="showDropdownInSaved && allPointsFiltered.length && inputSelected">
            <div class="item" *ngFor="let item of allPointsFiltered; let i=index" (click)="newPointFromSaved(item)" [ngClass]="{active: selectedPointFiltered == i + 1}">
              <div *ngIf="i<5">
                <div class="icon" id="{{i + 1}}"></div>
                <span class="title">{{ item.title }}</span>
                <span class="description" *ngIf="item.description">, {{ item.description }}</span>
              </div>
            </div>
          </div>
          <div class="dropdown-in-saved" *ngIf="showDropdownInSaved && !allPointsFiltered.length && inputSelected">
            <div class="item">
              Aucune correspondance
            </div>
          </div>
        </div>
        <div class="new-lat-lon" *ngIf="newLatLonTriggered">
          <div class="sur-head">
            <div class="close" (click)="closeNewLatLon()">
              <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
              </svg>
            </div>
          </div>
          <div class="title">
            <input
            type="text"
            placeholder="Titre du point"
            [(ngModel)]="newLatLonTitle"
            />
          </div>
          <div class="inputs">
            <input
            type="text"
            placeholder="Latitude"
            [(ngModel)]="newLat"
            />
            <input
            type="text"
            placeholder="Longitude"
            [(ngModel)]="newLon"
            />
          </div>
          <div class="validate">
            <div (click)="newPointFromLatLon()" [ngClass]="{'disabled' : !newLatLonTitle || !newLat || !newLon}">
              Valider
            </div>
          </div>
        </div>
        <div class="other-options">
          <div (click)="triggerNewLatLon()" *ngIf="!newLatLonTriggered">
            Ajouter un point via sa latitude/longitude
          </div>
        </div>
        <div class="points-list">
          <div class="points" *ngIf="activePointsAndVariations.length" [sortablejs]="activePointsAndVariations" [sortablejsOptions]="options">
            <div *ngFor="let point of activePointsAndVariations; let i = index">
              <div class="single-point">
                <div class="index">
                  {{ i + 1 }}.
                </div>
                <div class="point">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M507.353,245.245l-83.692-78.769c-4.289-4.039-10.57-5.141-15.98-2.803c-5.409,2.337-8.911,7.665-8.911,13.558v34.462
                          h-98.462v-98.462h34.462c5.892,0,11.221-3.502,13.558-8.911c2.337-5.409,1.236-11.69-2.803-15.98L266.755,4.647
                          C263.964,1.682,260.072,0,256,0s-7.964,1.682-10.755,4.647L166.476,88.34c-4.039,4.29-5.14,10.571-2.803,15.98
                          c2.337,5.409,7.665,8.911,13.558,8.911h34.462v98.462h-98.462v-34.462c0-5.892-3.502-11.221-8.911-13.558
                          c-5.41-2.337-11.69-1.236-15.98,2.803L4.647,245.245C1.682,248.036,0,251.928,0,256c0,4.072,1.682,7.964,4.647,10.755
                          l83.692,78.769c4.29,4.039,10.57,5.141,15.98,2.803c5.409-2.337,8.911-7.665,8.911-13.558v-34.462h98.462v98.462h-34.462
                          c-5.892,0-11.221,3.502-13.558,8.911c-2.337,5.409-1.236,11.69,2.803,15.98l78.769,83.692c2.79,2.966,6.683,4.647,10.755,4.647
                          c4.072,0,7.964-1.682,10.755-4.647l78.769-83.692c4.039-4.29,5.14-10.571,2.803-15.98c-2.337-5.409-7.665-8.911-13.558-8.911
                          h-34.462v-98.462h98.462v34.462c0,5.892,3.502,11.221,8.911,13.558c5.41,2.337,11.691,1.236,15.98-2.803l83.692-78.769
                          c2.966-2.79,4.647-6.683,4.647-10.755S510.318,248.036,507.353,245.245z"/>
                      </g>
                    </g>
                  </svg>
                  <div class="point-title">
                    {{ point.title }}
                  </div>
                  <div *ngIf="!point.point_id" class="info-point" (click)="editPoint(i)">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
                            C512,114.497,397.492,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
                            c119.393,0,216,96.615,216,216C472,375.393,375.384,472,256,472z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M256,214.33c-11.046,0-20,8.954-20,20v128.793c0,11.046,8.954,20,20,20s20-8.955,20-20.001V234.33
                            C276,223.284,267.046,214.33,256,214.33z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <circle cx="256" cy="162.84" r="27"/>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div *ngIf="point.point_id" class="info-point" (click)="editVariation(i)">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
                            C512,114.497,397.492,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
                            c119.393,0,216,96.615,216,216C472,375.393,375.384,472,256,472z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M256,214.33c-11.046,0-20,8.954-20,20v128.793c0,11.046,8.954,20,20,20s20-8.955,20-20.001V234.33
                            C276,223.284,267.046,214.33,256,214.33z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <circle cx="256" cy="162.84" r="27"/>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div *ngIf="!point.point_id" class="add-variation" (click)="openModalExplanations()">
                    Ajouter une variation
                  </div>
                  <div class="remove" (click)="onRemovePoint(point.id)">
                    <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-points" *ngIf="!activePointsAndVariations.length">
            Aucun point sélectionné
          </div>
        </div>
      </div>
      <div class="boutons-change">
        <div class="bouton-prev">
          <a style="cursor:pointer;" routerLink="/create-circuit-1">
            << Revenir
          </a>
        </div>
        <div class="bouton-next">
          <a style="cursor:pointer;" routerLink="/create-circuit-3" [ngClass]="{disabled: !showPoints}">
            Suivant >>
          </a>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="selectedTravelMode">
        <div class="select walking" (click)="changeTravelMode('WALKING')" [ngClass]="{active: travelMode == 'WALKING'}">
          Marche
        </div>
        <div class="select bycicle" (click)="changeTravelMode('BICYCLING')" [ngClass]="{active: travelMode == 'BICYCLING'}">
          Vélo
        </div>
        <div class="select car" (click)="changeTravelMode('DRIVING')" [ngClass]="{active: travelMode == 'DRIVING'}">
          Voiture
        </div>
        <div class="select none" (click)="changeTravelMode('NONE')" [ngClass]="{active: travelMode == 'NONE'}">
          Retirer le tracé
        </div>
      </div>
      <div id="map">

      </div>
    </div>
  </div>
  <div class="new-point" *ngIf="modalNewPoint">
    <app-modal-create-point order="{{activePointsAndVariations.length + 1}}" routeId="{{routeId}}" [permission]="pointPermission" [existingPoint]="editExistingPoint" [editingFromList]="editingFromList"></app-modal-create-point>
  </div>
  <div class="new-variation" *ngIf="modalNewVariation">
    <app-modal-create-variation order="{{activePointsAndVariations.length + 1}}" routeId="{{routeId}}" [permission]="pointPermission" [editingFromList]="editingFromList"></app-modal-create-variation>
  </div>
  <div class="explanations" *ngIf="modalExplanations">
    <div class="contenu">
      <div class="head">
        <div class="close" (click)="closeModalExplanations()">
          <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
          </svg>
        </div>
      </div>
      <div class="title">
        Ajouter une variation
      </div>
      <div class="description">
        Pour ajouter une variation glissez le point principal vers l'endroit où vous souhaitez créer une variation.
      </div>
      <div class="gif">
        <img src="../../../../assets/gifs/add-variation.gif"/>
      </div>
    </div>
  </div>
</div>
