import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-modal-create-variation',
  templateUrl: './modal-create-variation.component.html',
  styleUrls: ['./modal-create-variation.component.scss']
})
export class ModalCreateVariationComponent implements OnInit {

  @Input() publicationId: any;
  @Input() routeId: any;
  @Input() order: any;
  @Input() permission: boolean;
  @Input() editingFromList: boolean;

  parentPoint: any;
  variation: any;

  lat: any;
  lon: any;

  title: string;
  description: string;
  moreinfo: string;
  categorie: any;
  variationUrl: string;
  filesModal: any = [];

  map: google.maps.Map = null;
  placesService: google.maps.places.PlacesService;
  googleInfos: any;

  filesSound: any = [];
  filesVideo: any = [];

  videoType: any;
  videoCode: any;

  starIcon = {
    url: "../../../../assets/images/star.png",
    scaledSize: new google.maps.Size(50, 50)
  };

  categories: any = [];
  mobileLayout: boolean = false;

  cropImg: boolean = false;
  imgToCrop: any;
  croppingFile: any;

  constructor(private apiService: ApiService, private commonService: CommonService, private http: HttpClient, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (window.innerWidth < 1000) {
      this.mobileLayout = true;
    } else {
      this.mobileLayout = false;
    }
    this.getCategories();
    this.commonService.currentPoint.subscribe(      
      point => {
        if (point.hasOwnProperty('point_id')) {
          this.variation = point;
          this.apiService.getPoint(this.variation.point_id).subscribe(
            data => {
              this.parentPoint = data.data;
              if (this.parentPoint.place_id || this.parentPoint.googleplace_id) {
                this.getPointGoogleDetails(this.parentPoint);
              }
            },
            err => {
              console.log(err);
            }
          );
          this.title = this.variation.title;
          this.description = this.variation.description;
          this.moreinfo = this.variation.moreinfo;
          this.categorie = this.variation.catpoint_id;
          this.variationUrl = this.variation.url;
          if (this.variation.medias) {
            this.variation.medias.forEach((element) => {
              var blob = null;
              var xhr = new XMLHttpRequest();
              xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
              xhr.responseType = "blob";
              xhr.onload = () => 
              {
                  blob = xhr.response;
                  let format = element.url.substring(element.url.lastIndexOf('.') + 1);
                  let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
                  var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
                  if (element.url.slice(element.url.length - 3) == 'mp3') {
                    this.filesSound.push(file);
                  } else {
                    this.filesModal.push(file);
                  }
              }
              xhr.send();
            });
          }
          if (this.variation.video_codes) {
            if (this.variation.video_codes.length) {
              this.videoType = this.variation.video_codes[0].type;
              this.videoCode = this.variation.video_codes[0].code;
            }
          }
          this.lat = this.variation.lat;
          this.lon = this.variation.lon;
          this.initMap();
        } else {
          this.parentPoint = point;
          this.lat = this.parentPoint.newVariationLat;
          this.lon = this.parentPoint.newVariationLon;
          this.initMap();
        }
      },
      err => {
        console.log(err);
      }
    ).unsubscribe();
  }

  initMap() {
    let position = new google.maps.LatLng(this.lat, this.lon);
    this.map = new google.maps.Map(document.getElementById("map-modal"), {
      center: position,
      zoom: 20
    });
    this.addMarker(position);
  }

  addMarker(position) {
    let marker = new google.maps.Marker({
      icon: this.starIcon,
      position: position,
    });
    marker.setMap(this.map);
  }

  getPointGoogleDetails(element) {
    const request = { placeId: element.googleplace_id || element.place_id};

    this.googleInfos = {}; 

    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.placesService.getDetails(request, (place, status) => {
      if (status === 'OK') {
        if (place.name) this.googleInfos.title = place.name;
        if (place.formatted_address && place.opening_hours) {
          this.googleInfos.more = place.formatted_address + '\n\n' + place.opening_hours.weekday_text;
        } else if (place.formatted_address) {
          this.googleInfos.more = place.formatted_address;
        } else if (place.opening_hours) {
          this.googleInfos.more = place.opening_hours.weekday_text;
        }
        if (place.photos) {
          var photos = place.photos;
          var urls = []; // we will store the urls here
          photos.slice(0, 6).forEach((photo) => {
            urls.push(
              photo.getUrl({
                maxWidth: 800,
                maxHeight: undefined
              })
            );
          });
          this.googleInfos.medias = urls;
          this.detectChanges();
        }
      }
    });
  }

  onCopyData(data, type) {
    if (!this.permission) return;
    if (type == 'title') this.title = data;
    if (type == 'description') this.description = data;
    if (type == 'moreinfo') this.moreinfo = data;
  }

  onCopyImage(targetUrl) {
    if (!this.permission) return;
    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    this.http.get(targetUrl, {responseType: 'blob'}).subscribe(data => {
      let extension = data.type.split('/')[1];
      let name = 'from-google-' + (+new Date).toString(36).slice(-5) + '.' + extension;
      let file = new File([data], name, {type: data.type, lastModified: +new Date});
      this.filesModal.push(file);
      this.detectChanges();
    })
  }

  onSelect(event) {
    if (!this.permission) return;
    let files = event.addedFiles;
    files.forEach(file => {
      this.filesModal.push(file);
    });
  }

  onCropImg(file) {
    if (!this.permission) return;
    this.imgToCrop = file;
    this.cropImg = true;
    this.croppingFile = file;
  }

  onCroppedImg(e) {
    if (!this.permission) return;
    if (e) {
      if (this.filesModal.indexOf(this.croppingFile) !== -1) {
        this.filesModal.splice(this.filesModal.indexOf(this.croppingFile), 1);
        this.filesModal.push(e);
      }
    }
    this.cropImg = false;
  }

  onRemove(event) {
    if (!this.permission) return;
    this.filesModal.splice(this.filesModal.indexOf(event), 1);
  }

  onSelectSound(event) {
    if (!this.permission) return;
    if (!this.filesSound.length) {
      this.filesSound.push(...event.addedFiles);
    }
  }

  onRemoveSound(event) {
    if (!this.permission) return;
    this.filesSound.splice(this.filesSound.indexOf(event), 1);
  }

  onSelectVideo(event) {
    if (!this.permission) return;
    this.filesVideo.push(...event.addedFiles);
  }

  onRemoveVideo(event) {
    if (!this.permission) return;
    this.filesVideo.splice(this.filesVideo.indexOf(event), 1);
  }

  addVideo(type: any) {
    if (!this.permission) return;
    this.videoType = type;
  }

  onDeleteVideo() {
    if (!this.permission) return;
    this.videoCode = '';
    this.videoType = '';
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  getCategories() {
    this.apiService.listCatpoint().subscribe(
      data => {
        this.categories = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  async saveVariation() {
    if (this.variation && this.variation.video_codes) {
      if (this.variation.video_codes.length) {
        if (this.variation.video_codes[0].type !== this.videoType || this.variation.video_codes[0].code !== this.videoCode) {
          this.apiService.variationUnlinkVideos(this.variation.id, this.variation.video_codes[0].id).subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          )
        }
      }
    }
    let city;
    if (this.variation) {
      city = this.variation.city
    } else {
      city = this.parentPoint.city
    }
    let video;
    if (this.videoType && this.videoCode) video = [{type: this.videoType, code: this.videoCode, order: '1'}];

    let filesResized = [];
    for await (const file of this.filesModal) {
      await this.resizeImage(file, 1500, 1500).then(blob => {
        let resizedFile = new File([blob], file.name, {type: blob.type});
        filesResized.push(resizedFile);
      }, err => {
        console.error("Photo error", err);
      });
    };

    let medias = [...filesResized, ...this.filesSound];
    if (this.variation) {
      if (this.variation.medias) {
        if (this.variation.medias.length) {
          this.variation.medias.forEach(element => {
            if (!medias.some(media => media.name == element.file_name)) {
              this.apiService.unlinkMedia(element.id).subscribe(
                data => {
                },
                err => {
                  console.log(err);
                }
              );
            }
          });
        }     
      }   
    }
    let variationID;
    (this.variation) ? variationID = this.variation.id : variationID = null;
    this.apiService.addVariation(
      this.parentPoint.id, 
      this.title, 
      this.lat, 
      this.lon, 
      { variation_id: variationID,
        article_id: this.publicationId, 
        route_id: this.routeId,
        description: this.description,
        moreinfo: this.moreinfo, 
        medias: medias,
        order: this.order,
        etat: '2',
        city: city,
        video_codes: video,
        catpoint_id: this.categorie,
        url: this.variationUrl
      })
      .subscribe(
      data => {
        this.apiService.getVariation(data.data.variation_id).subscribe(      
          data => {
            let message;
            (this.variation) ? message = 'variation updated' : message = 'variation saved';
            this.commonService.changePoint(data.data);
            this.commonService.changeMessage(message);
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  closeModal() {
    this.commonService.changeMessage('close modal');
  }

  ngOnDestroy() {
    this.commonService.changeMessage('default message');
  }

  async resizeImage(file:File, maxWidth:number, maxHeight:number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
          let width = image.width;
          let height = image.height;
          
          if (width <= maxWidth && height <= maxHeight) {
              resolve(file);
          }

          let newWidth;
          let newHeight;

          if (width > height) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
          } else {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
          }

          let canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          let context = canvas.getContext('2d');

          context.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

}