import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private messageSource = new BehaviorSubject("default message");
  currentMessage = this.messageSource.asObservable();

  private pointSource = new BehaviorSubject({});
  currentPoint = this.pointSource.asObservable();

  private messageSourceDuplicate = new BehaviorSubject("default message");
  currentMessageDuplicate = this.messageSourceDuplicate.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changePoint(point: any) {
    this.pointSource.next(point);
  }

  changeMessageDuplicate(message: string) {
    this.messageSourceDuplicate.next(message);
  }

}