import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss']
})
export class ImgCropperComponent implements OnInit {

  @Input() img: any;
  @Input() isModal: boolean;

  imageChangedEvent: any = '';
  cropped: any;
  croppedImage: any = '';

  @Output() fileCropped: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {

  }

  imageCropped(event: ImageCroppedEvent) {
    this.cropped = event;
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onValidate() {
    this.urltoFile(this.cropped.base64, this.img.name.split('.')[0] + '.png', 'image/png').then(
      file => this.fileCropped.emit(file)
    );
  }

  closeModal() {
    this.fileCropped.emit();
  }

  urltoFile(url, filename, mimeType){
    return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

}
