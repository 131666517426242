import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-langues',
  templateUrl: './langues.component.html',
  styleUrls: ['./langues.component.scss']
})
export class LanguesComponent implements OnInit {

  isLoading: boolean = false;

  langues: any = [];

  newLangTriggered: boolean = false;
  newLangName: string;
  newLangCode: string;

  editLangTriggered: boolean = false;
  langEdit: any;
  editLangName: string;
  editLangCode: string;

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadLangs();
  }

  loadLangs() {
    this.apiService.getLangs().subscribe(
      data => {
        this.langues = data.data;
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  onAddLang() {
    this.newLangTriggered = true;
  }

  closeNewTrad() {
    this.newLangTriggered = false;
  }

  newLang() {
    this.isLoading = true;
    this.apiService.addLang(this.newLangName, this.newLangCode).subscribe(
      data => {
        this.newLangTriggered = false;
        this.newLangName = '';
        this.newLangCode = '';
        this.loadLangs();
        this.toastr.success('Langue ajoutée');
      },
      err => {
        console.log(err);
      }
    );
  }

  onEdit(id) {
    this.editLangTriggered = true;
    this.langEdit = this.langues.filter(obj => {
      return obj.id == id
    });
    this.editLangName = this.langEdit[0].name;
    this.editLangCode = this.langEdit[0].code_lang;
  }

  closeEditModal() {
    this.editLangTriggered = false;
    this.langEdit = 0;
  }

  editLang() {
    this.isLoading = true;
    this.apiService.addLang(this.editLangName, this.editLangCode, this.langEdit[0].id).subscribe(
      data => {
        this.langEdit = 0;
        this.loadLangs();
        this.editLangTriggered = false;
        this.toastr.success('Langue modifiée');
      },
      err => {
        console.log(err);
      }
    );
  }

}
