<div class="contenu" *ngIf="langs">
  <div class="sur-head">
    <div class="close" (click)="closeModal()">
      <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
      </svg>
    </div>
  </div>
  <div class="content start" *ngIf="!duplicationDone && !loading">
    <div class="title">
      Dupliquer le parcours
      <br>
      <span>
        "{{ title }}"
      </span>
      <br>
      en
    </div>
    <select [(ngModel)]="duplicationLang">
      <option value="" disabled selected>Langue</option>
      <option *ngFor="let lang of langs" [value]="lang.code_lang">
        {{ lang.name }}
      </option>
    </select>
    <div class="validate">
      <a class="btn" [ngClass]="{'disabled' : !duplicationLang}" (click)="onDuplicate()">
        Dupliquer
      </a>
    </div>
    <div class="notice">
      Attention, en dupliquant, vous dupliquez l'ensemble des informations liées au parcours (points, variations, médias) vers une autre langue. Si vos points existent déjà, il risque d'y avoir des doublons.
    </div>
  </div>
  <div class="content done" *ngIf="duplicationDone && !loading">
    <div class="btns">
      <a class="btn" (click)="onSeeList()">
        Voir la liste des parcours en {{ duplicatedLangTitle }}
      </a>
      <a class="btn" (click)="onEditDuplicatedRoute()">
        Éditer le parcours dupliqué
      </a>
    </div>
  </div>
  <div class="content loading" *ngIf="!duplicationDone && loading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>