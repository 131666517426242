import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-modal-duplicate',
  templateUrl: './modal-duplicate.component.html',
  styleUrls: ['./modal-duplicate.component.scss']
})
export class ModalDuplicateComponent implements OnInit {

  loading: boolean = false;
  duplicationDone: boolean = false;

  @Input() id: any;
  @Input() title: any;

  langs: any = [];
  duplicationLang: any;
  duplicatedLangTitle: any;

  messageSubscription: Subscription;

  newRoute: any;

  constructor(private router: Router, private toastr: ToastrService, private commonService: CommonService, private apiService: ApiService) { }

  ngOnInit(): void {
    let currentLang = JSON.parse(localStorage.getItem('currentLang'));
    this.apiService.getLangs().subscribe(
      data => {
        data.data.forEach(lang => {
          if (lang.code_lang !== currentLang) {
            this.langs.push(lang);
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onDuplicate() {
    this.loading = true;
    this.apiService.duplicateRoute(this.duplicationLang, this.id).subscribe(
      data => {
        this.duplicationDone = true;
        this.loading = false;
        this.duplicatedLangTitle = this.langs.find(el => el.code_lang == this.duplicationLang).name;
        this.newRoute = data.data.route_id;
        this.toastr.success('Circuit dupliqué');
      },
      err => {
        this.duplicationDone = false;
        this.loading = false;
        this.toastr.error('Une erreur est survenue');
        console.log(err);
      }
    );
  }

  onSeeList() {
    localStorage.setItem('currentLang', JSON.stringify(this.duplicationLang));
    window.location.reload();
  }

  onEditDuplicatedRoute() {
    localStorage.setItem('currentLang', JSON.stringify(this.duplicationLang));
    localStorage.setItem('currentRouteId', this.newRoute);
    this.router.navigate(['/create-circuit-1']).then(() => {
      window.location.reload();
    });
  }

  closeModal() {
    this.commonService.changeMessageDuplicate('close modal');
  }

  ngOnDestroy() {
    this.commonService.changeMessageDuplicate('default message');
  }

}
