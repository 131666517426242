<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Statistiques
    </div>
    <div class="filters">
      <div class="title">
        Filtrer par dates
      </div>
      <div class="date-time">
        <div class="start">
          <ngx-datepicker [(ngModel)]="datePickerStart" [options]="optionsDatePicker" (ngModelChange)="setStartDate($event)"></ngx-datepicker>
        </div>
        <div class="end">
          <ngx-datepicker [(ngModel)]="datePickerEnd" [options]="optionsDatePicker" (ngModelChange)="setEndDate($event)"></ngx-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="contenu-page">
    <div class="stats">
      <div class="global" *ngIf="globalStats">
        <div class="bloc users">
          <div class="title">
            Total utilisateurs
          </div>
          <div class="data">
            {{ globalStats.total_users }}
          </div>
        </div>
        <div class="bloc devices">
          <div class="title">
            Total appareils
          </div>
          <div class="data">
            {{ globalStats.total_devices }}
          </div>
        </div>
        <div class="bloc vues">
          <div class="title">
            Total vues
          </div>
          <div class="data">
            {{ globalStats.vues }}
          </div>
        </div>
        <div class="bloc devices-user">
          <div class="title">
            Appareils / user
          </div>
          <div class="data">
            {{ globalStats.average_devices | number : '1.2-2' }}
          </div>
        </div>
        <div class="bloc vues-users">
          <div class="title">
            Vues / user
          </div>
          <div class="data">
            {{ globalStats.average_vues | number : '1.2-2' }}
          </div>
        </div>
        <div class="bloc gps">
          <div class="title">
            % GPS
          </div>
          <div class="data">
            {{ globalStats.average_geo_gps | number : '1.2-2' }}
          </div>
        </div>
      </div>
      <div class="graphs">
        <div class="graph users" *ngIf="usersChartData">
          <div class="title">
            Utilisateurs
          </div>
          <div class="data">
            <div class="chart-wrapper">
              <canvas baseChart 
                [datasets]="usersChartData" 
                [labels]="usersChartLabels" 
                [options]="lineChartOptions"
                [colors]="lineChartColors" 
                [legend]="lineChartLegend" 
                [chartType]="lineChartType" 
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
        <div class="graph vues" *ngIf="vuesChartData">
          <div class="title">
            Vues
          </div>
          <div class="data">
            <div class="chart-wrapper">
              <canvas baseChart 
                [datasets]="vuesChartData" 
                [labels]="vuesChartLabels" 
                [options]="lineChartOptions"
                [colors]="lineChartColors" 
                [legend]="lineChartLegend" 
                [chartType]="lineChartType" 
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
        <div class="graph devices" *ngIf="devicesChartData">
          <div class="title">
            Appareils
          </div>
          <div class="data">
            <div class="chart-wrapper">
              <canvas baseChart 
                [datasets]="devicesChartData" 
                [labels]="devicesChartLabels" 
                [options]="lineChartOptions"
                [colors]="lineChartColors" 
                [legend]="lineChartLegend" 
                [chartType]="lineChartType" 
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tops">
      <div class="top top-vues" *ngIf="topVues && topVues.length">
        <div class="title">
          Top 10 vues
        </div>
        <div class="data">
          <div class="head">
            <div class="index">
              Pos.
            </div>
            <div class="title">
              Titre
            </div>
            <div class="vues">
              Vues
            </div>
          </div>
          <div class="vue" *ngFor="let vue of topVues; let i = index" (click)="onViewSingle(vue)">
            <span class="index">
              {{ i + 1 }}.
            </span>
            <span class="title">
              {{ vue.title }}
            </span>
            <span class="count">
              {{ vue.count }}
            </span>
          </div>
        </div>
      </div>
      <div class="top top-publications" *ngIf="topArticles && topArticles.length">
        <div class="title">
          Top 10 publications
        </div>
        <div class="data">
          <div class="head">
            <div class="index">
              Pos.
            </div>
            <div class="title">
              Titre
            </div>
            <div class="vues">
              Vues
            </div>
          </div>
          <div class="vue" *ngFor="let article of topArticles; let i = index" (click)="onViewSingle(article)">
            <span class="index">
              {{ i + 1 }}.
            </span>
            <span class="title">
              {{ article.title }}
            </span>
            <span class="count">
              {{ article.count }}
            </span>
          </div>
        </div>
      </div>
      <div class="top top-routes" *ngIf="topRoutes && topRoutes.length">
        <div class="title">
          Top 10 circuits
        </div>
        <div class="data">
          <div class="head">
            <div class="index">
              Pos.
            </div>
            <div class="title">
              Titre
            </div>
            <div class="vues">
              Vues
            </div>
          </div>
          <div class="vue" *ngFor="let route of topRoutes; let i = index" (click)="onViewSingle(route)">
            <span class="index">
              {{ i + 1 }}.
            </span>
            <span class="title">
              {{ route.title }}
            </span>
            <span class="count">
              {{ route.count }}
            </span>
          </div>
        </div>
      </div>
      <div class="top top-parcours" *ngIf="topParcours && topParcours.length">
        <div class="title">
          Top 10 lancements de circuit
        </div>
        <div class="data">
          <div class="head">
            <div class="index">
              Pos.
            </div>
            <div class="title">
              Titre
            </div>
            <div class="vues">
              Vues
            </div>
          </div>
          <div class="vue" *ngFor="let parcours of topParcours; let i = index" (click)="onViewSingle(parcours)">
            <span class="index">
              {{ i + 1 }}.
            </span>
            <span class="title">
              {{ parcours.title }}
            </span>
            <span class="count">
              {{ parcours.count }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="map-container" *ngIf="markers">
      <div class="title" *ngIf="!isLoading">
        Dernières positions de vos utilisateurs
      </div>
      <div class="map-loading" *ngIf="mapLoading && !isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <div id="map" #map [ngStyle]="{'opacity':mapLoading ? '0' : '1' }">
      </div>
    </div>
  </div>
</div>