<div class="field title-app">
  <div class="title">
    Titre de l'application
  </div>
  <div class="input">
    <input
      type="text"
      placeholder="Titre de l'application"
      [(ngModel)]="appTitle"
    />
  </div>
  <div class="description">
    Attention le titre de l'application change les titrages dans l'application et sur les balises de référencement. Cela ne changera pas le titre de l'application elle-même.
  </div>
</div>

<div class="field meta-description">
  <div class="title">
    Méta description
  </div>
  <div class="input">
    <input
      type="text"
      placeholder="Méta description"
      [(ngModel)]="metaDescription"
    />
  </div>
  <div class="description">
    Description de votre application pour les moteurs de recherche. Ne doit pas dépasser 160 caractères.
  </div>
</div>

<div class="colors">
  <div class="color" *ngFor="let color of colors">
    <div class="title">
      {{ color.title }}
    </div>
    <div class="picker">
      <input
        [value]="color.value"
        [style.background]="color.value"
        [(colorPicker)]="color.value"
      />
    </div>
  </div>
</div>

<div class="save">
  <div class="bouton" (click)="onSave()">
    Enregistrer
  </div>
</div>