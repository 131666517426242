import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-point',
  templateUrl: './edit-point.component.html',
  styleUrls: ['./edit-point.component.scss']
})
export class EditPointComponent implements OnInit {

  isLoading: boolean = false;
  sub: Subscription;
  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  pointId: any;
  type: any;

  point: any = {};
  title: any;
  description: any;
  moreinfo: any;
  categorie: any;
  pointUrl: any;
  filesImage: any = [];
  filesSound: any = [];
  filesVideo: any = [];
  videoType: any;
  videoCode: any;
  categories: any = [];

  map: google.maps.Map = null;
  placesService: google.maps.places.PlacesService;
  googleInfos: any;

  starIcon = {
    url: "../../../../../assets/images/star.png",
    scaledSize: new google.maps.Size(50, 50)
  };

  cropImg: boolean = false;
  imgToCrop: any;
  croppingFile: any;

  constructor(private http: HttpClient, private changeDetectorRef: ChangeDetectorRef, private _Activatedroute:ActivatedRoute, private _router:Router, private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.sub = this._Activatedroute.paramMap.subscribe(params => { 
      this.pointId = params.get('id');
      this.type = params.get('type');
      this.getInfos();
   });
  }

  getInfos() {
    this.getCategories();
    if (this.type == 'point') {
      this.apiService.getPoint(this.pointId).subscribe(
        data => {
          this.point = data.data;
          this.populateFields();
        },
        err => {
          console.log(err);
        }
      );
    } else if (this.type == 'variation') {
      this.apiService.getVariation(this.pointId).subscribe(
        data => {
          this.point = data.data;
          this.populateFields();
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  populateFields() {
    if (this.point.utilisateur_id !== Number(this.userID)) this.goBack();
    this.initMap();
    if (this.point.googleplace_id) {
      this.googleInfos = {}; 
      this.getPointGoogleDetails(this.point.googleplace_id);
    } else if (this.point.point_id) {
      this.apiService.getPoint(this.point.point_id).subscribe(
        data => {
          if (data.data.googleplace_id) {
            this.googleInfos = {}; 
            this.getPointGoogleDetails(data.data.googleplace_id);      
          }
        },
        err => {
          console.log(err);
        }
      );
    }
    this.title = this.point.title;
    this.description = this.point.description;
    this.moreinfo = this.point.moreinfo;
    this.categorie = this.point.catpoint_id;
    this.pointUrl = this.point.url;
    if (this.point.medias) {
      this.point.medias.forEach((element) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
        xhr.responseType = "blob";
        xhr.onload = () => 
        {
            blob = xhr.response;
            let format = element.url.substring(element.url.lastIndexOf('.') + 1);
            let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
            var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
            if (element.url.slice(element.url.length - 3) == 'mp3') {
              this.filesSound.push(file);
            } else {
              this.filesImage.push(file);
            }
        }
        xhr.send();
      });
    }
    if (this.point.video_codes.length) {
      this.videoType = this.point.video_codes[0].type;
      this.videoCode = this.point.video_codes[0].code;
    }
    this.isLoading = false;
  }

  getPointGoogleDetails(googleplace_id) {
    const request = { placeId: googleplace_id };

    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.placesService.getDetails(request, (place, status) => {
      if (status === 'OK') {
        if (place.name) this.googleInfos.title = place.name;
        if (place.formatted_address && place.opening_hours) {
          this.googleInfos.more = place.formatted_address + '\n\n' + place.opening_hours.weekday_text;
        } else if (place.formatted_address) {
          this.googleInfos.more = place.formatted_address;
        } else if (place.opening_hours) {
          this.googleInfos.more = place.opening_hours.weekday_text;
        }
        if (place.photos) {
          var photos = place.photos;
          var urls = []; // we will store the urls here
          photos.slice(0, 6).forEach((photo) => {
            urls.push(
              photo.getUrl({
                maxWidth: 800,
                maxHeight: undefined
              })
            );
          });
          this.googleInfos.medias = urls;
        }
        this.detectChanges();
        this.isLoading = false;
      }
    });
  }

  initMap() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(this.point.lat, this.point.lon),
      zoom: 15
    });
    let position = new google.maps.LatLng(this.point.lat, this.point.lon);
    this.addMarker(position);
  }

  addMarker(position) {
    let marker = new google.maps.Marker({
      icon: this.starIcon,
      position: position,
    });
    marker.setMap(this.map);
  }

  onCopyData(data, type) {
    if (type == 'title') this.title = data;
    if (type == 'description') this.description = data;
    if (type == 'moreinfo') this.moreinfo = data;
  }

  onCopyImage(targetUrl) {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    this.http.get(targetUrl, {responseType: 'blob'}).subscribe(data => {
      let extension = data.type.split('/')[1];
      let name = 'from-google-' + (+new Date).toString(36).slice(-5) + '.' + extension;
      let file = new File([data], name, {type: data.type, lastModified: +new Date});
      this.filesImage.push(file);
      this.detectChanges();
    })
  }

  onSelect(event) {
    let files = event.addedFiles;
    files.forEach(file => {
      this.filesImage.push(file);
    });
  }

  onCropImg(file) {
    this.imgToCrop = file;
    this.cropImg = true;
    this.croppingFile = file;
  }

  onCroppedImg(e) {
    if (e) {
      if (this.filesImage.indexOf(this.croppingFile) !== -1) {
        this.filesImage.splice(this.filesImage.indexOf(this.croppingFile), 1);
        this.filesImage.push(e);
      }
    }
    this.cropImg = false;
  }

  onRemove(event) {
    this.filesImage.splice(this.filesImage.indexOf(event), 1);
  }

  onSelectSound(event) {
    if (!this.filesSound.length) {
      this.filesSound.push(...event.addedFiles);
    }
  }

  onRemoveSound(event) {
    this.filesSound.splice(this.filesSound.indexOf(event), 1);
  }

  onSelectVideo(event) {
    this.filesVideo.push(...event.addedFiles);
  }

  onRemoveVideo(event) {
    this.filesVideo.splice(this.filesVideo.indexOf(event), 1);
  }

  addVideo(type: any) {
    this.videoType = type;
  }

  onDeleteVideo() {
    this.videoCode = '';
    this.videoType = '';
  }

  getCategories() {
    this.apiService.listCatpoint().subscribe(
      data => {
        this.categories = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  async savePoint() {
    this.isLoading = true;
    if (this.point.video_codes.length) {
      if (this.point.video_codes[0].type !== this.videoType || this.point.video_codes[0].code !== this.videoCode) {
        this.apiService.pointUnlinkVideos(this.point.id, this.point.video_codes[0].id).subscribe(
          data => {
          },
          err => {
            console.log(err);
          }
        )
      }
    }
    let video;
    if (this.videoType && this.videoCode) video = [{type: this.videoType, code: this.videoCode, order: '1'}];

    let filesResized = [];
    for await (const file of this.filesImage) {
      await this.resizeImage(file, 1500, 1500).then(blob => {
        let resizedFile = new File([blob], file.name, {type: blob.type});
        filesResized.push(resizedFile);
      }, err => {
        console.error("Photo error", err);
      });
    };

    let medias = [...filesResized, ...this.filesSound];
    if (this.point.medias.length) {
      this.point.medias.forEach(element => {
        if (!medias.some(media => media.name == element.file_name)) {
          this.apiService.unlinkMedia(element.id).subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }
    this.apiService.addPoint(
      this.point.lat, 
      this.point.lon, 
      this.title, 
      { point_id: this.point.id,
        googleplace_id: this.point.place_id || this.point.googleplace_id, 
        description: this.description, 
        moreinfo: this.moreinfo, 
        medias: medias,
        etat: '2',
        city: this.point.city,
        video_codes: video,
        catpoint_id: this.categorie,
        url: this.pointUrl
      })
      .subscribe(    
      data => {
        this.toastr.success('Point modifié');
        this._router.navigate(['/points']);
      },
      err => {
        console.log(err);
      }
    );
  }

  async saveVariation() {
    this.isLoading = true;
    if (this.point.video_codes.length) {
      if (this.point.video_codes[0].type !== this.videoType || this.point.video_codes[0].code !== this.videoCode) {
        this.apiService.variationUnlinkVideos(this.point.id, this.point.video_codes[0].id).subscribe(
          data => {
          },
          err => {
            console.log(err);
          }
        )
      }
    }
    let video;
    if (this.videoType && this.videoCode) video = [{type: this.videoType, code: this.videoCode, order: '1'}];

    let filesResized = [];
    for await (const file of this.filesImage) {
      await this.resizeImage(file, 1500, 1500).then(blob => {
        let resizedFile = new File([blob], file.name, {type: blob.type});
        filesResized.push(resizedFile);
      }, err => {
        console.error("Photo error", err);
      });
    };

    let medias = [...this.filesImage, ...this.filesSound];
    if (this.point.medias.length) {
      this.point.medias.forEach(element => {
        if (!medias.some(media => media.name == element.file_name)) {
          this.apiService.unlinkMedia(element.id).subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }
    this.apiService.addVariation(
      this.point.point_id,
      this.title,
      this.point.lat, 
      this.point.lon,  
      { variation_id: this.point.id,
        description: this.description, 
        moreinfo: this.moreinfo, 
        medias: medias,
        etat: '2',
        city: this.point.city,
        video_codes: video,
        catpoint_id: this.categorie,
        url: this.pointUrl
      })
      .subscribe(    
      data => {
        this.toastr.success('Point modifié');
        this._router.navigate(['/points']);
      },
      err => {
        console.log(err);
      }
    );
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  goBack() {
    this._router.navigate(['/points']);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async resizeImage(file:File, maxWidth:number, maxHeight:number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
          let width = image.width;
          let height = image.height;
          
          if (width <= maxWidth && height <= maxHeight) {
              resolve(file);
          }

          let newWidth;
          let newHeight;

          if (width > height) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
          } else {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
          }

          let canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          let context = canvas.getContext('2d');

          context.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

}