<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Personnalisation
    </div>
  </div>
  <div class="sub-header">
    <div class="choice">
      <div class="bouton" (click)="onGeneral()" [ngClass]="{'active':general}">
        Général
      </div>
      <div class="bouton" (click)="onAlgorithme()" [ngClass]="{'active':algorithme}">
        Algorithme
      </div>
      <div class="bouton" (click)="onCategorie()" [ngClass]="{'active':categorie}">
        Catégories
      </div>
    </div>
  </div>
  <div class="content-page">
    <div class="general" *ngIf="general">
      <app-personnalisation-generale [currentConfig]="currentConfig" (updateConfig)="updateConfig($event)"></app-personnalisation-generale>
    </div>
    <div class="algorithme" *ngIf="algorithme">
      <app-personnalisation-algorithme [currentConfig]="currentConfig" (updateConfig)="updateConfig($event)"></app-personnalisation-algorithme>
    </div>
    <div class="categories" *ngIf="categorie">
      <app-personnalisation-categories [currentCategories]="currentCategories" (updateCategories)="updateCategories($event)"></app-personnalisation-categories>
    </div>
  </div>
</div>
